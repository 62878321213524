import React, { useEffect, useState } from 'react'
import InputField from '../components/InputField'
import "../css/stream-details.css";
import { deleteStream, disableEnableStream, editStreamDetails, getStreamDetails, refreshRow } from '../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams} from "react-router-dom"
import { editOverviewDetails, loadData, login, setAlertProps} from '../redux/actions/streams-action';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { streamInitialstate, userInitialstate } from '../constants';
import { getFormattedInfo, getTextColor, setDisabled } from '../common-utils';
import refreshIcon from "../images/refresh.svg";
import deleteIcon from "../images/delete-btn.svg";
import DeleteDialog from './DeleteDialog';

const OverviewTab=()=>{
  const rowToEdit:any=useSelector((state:any)=>state?.rowToEdit);
  const [isDeleteClicked,setIsDeleteClicked]=useState(false);

  //console.log(rowToEdit);

  const dispatch:any=useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  let {id}=useParams();
  const [streamData,setStreamData]:any=useState({...streamInitialstate})
  const userDetails:any=useSelector((state:any)=>state?.user);
  console.log(streamData);
//   useEffect(()=>{
//     let interval = setInterval(() => {
//         getStreamDetails(id,userDetails).then((stream:any)=>{
//             dispatch(loadData(false));
//             if(!stream?.error){
//               dispatch(editOverviewDetails(stream?.data?.overViewData))
//               //dispatch(setAlertProps({message:"The stream has been updated successfully !!!",severity:"success"}));
//             }
//             stream?.error && dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
//         })
//     }, 3000);
//     return () => {
//         clearInterval(interval);
//     };
// })

useEffect(()=>{
    dispatch(loadData(true));
    getStreamDetails(id,userDetails).then((stream:any)=>{
        dispatch(loadData(false));
        if(!stream?.error){
            let data:any=stream?.data?.overViewData;
            setStreamData({
                name:{value:id||""},
                title:{value:data?.title||""},
                description:{value:data?.meta?.comments||""}});
          dispatch(editOverviewDetails(stream?.data?.overViewData))
          //dispatch(setAlertProps({message:"The stream has been updated successfully !!!",severity:"success"}));
        }
        stream?.error && dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
    })
  },[id])

  const handleClose = () => {
    setIsDeleteClicked(false);
  };

  const handleOk = () => {
    deleteStream(rowToEdit,userDetails).then((res:any)=>{
        if(res?.data?.status === 200){
            dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
            setIsDeleteClicked(false);
            navigation("/dashboard/live-streams")
         } else {
            setIsDeleteClicked(false)
             dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
         }
    });
};
  
  const onChangeValue=(e:any)=>{
     let streamDetails:any={...streamData,[e.target.name]:{...streamData[e.target.name],value:e.target.value}};
     setStreamData(streamDetails);
  }

  const disableStreamDetails=(disabled:boolean)=>{
    dispatch(loadData(true));
    disableEnableStream(rowToEdit,userDetails,disabled).then((res:any)=>{
        dispatch(loadData(false));
        if(res?.data?.status === 200){
            getStreamDetails(id,userDetails).then((stream:any)=>{
                dispatch(loadData(false));
                if(!stream?.error){
                  dispatch(editOverviewDetails(stream?.data?.overViewData))
                  dispatch(setAlertProps({message:"The stream has been updated successfully !!!",severity:"success"}));
                }
                stream?.error && dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
            })
           //dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
        } else {
            dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
        }
    });
}

const deleteStreamData = () => {
   setIsDeleteClicked(true); 
};

const refreshOverviewData=()=>{
    dispatch(loadData(true));
    refreshRow(rowToEdit,userDetails).then((res:any)=>{
        if(res?.data?.status === 200){
            getStreamDetails(id,userDetails).then((stream:any)=>{
                dispatch(loadData(false));
                if(!stream?.error){
                  dispatch(editOverviewDetails(stream?.data?.overViewData))
                  dispatch(setAlertProps({message:"The stream has been updated successfully !!!",severity:"success"}));
                }
                stream?.error && dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
            })
        } else {
            dispatch(loadData(false));
            dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
        }
    });
}


  const onSaveStreamData=()=>{
    dispatch(loadData(true));
    editStreamDetails(streamData,userDetails).then((res:any)=>{
      dispatch(loadData(false));
       if(res?.status ==200){
         dispatch(setAlertProps({message:"Stream has been updated Successfully!!!",severity:"success"}));
         //dispatch(setUserDetails(res?.data?.edit_auth));
         setStreamData({name:{value:rowToEdit?.name||""},title:{value:rowToEdit?.title||""},description:{value:rowToEdit?.meta?.comments||""}});
       }else{
        dispatch(setAlertProps({message:"Failed to update the stream",severity:"error"}));
       }
    });
   }
  return (<div style={{padding:"10px 0 20px 30px"}} className='flex-display'>
    {/* <Breadcrumb id="page-breadcrumb">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="#" active>
        Account Settings
      </Breadcrumb.Item>
    </Breadcrumb> */}
        <div style={{marginTop:"20px",width:"40%"}}>
          <InputField 
          type={"text"}
          name={"name"}
          label={'Name'} 
          value={streamData?.name?.value}
          isFloating={true}
          placeholder={''}
          error={streamData?.name?.error} 
          onChange={onChangeValue}/>
          <div style={{marginTop:"15px"}}><InputField 
          type={"text"}
          name={"title"}
          label={'Title'} 
          value={streamData?.title?.value}
          isFloating={true}
          placeholder={''} 
          onChange={onChangeValue}
          error={streamData?.title?.error}
          /></div>
          <div style={{marginTop:"15px"}}><InputField 
          type={"text"}
          name={"description"}
          label={'Description'} 
          value={streamData?.description?.value}
          isFloating={true}
          placeholder={''} 
          onChange={onChangeValue}
          error={streamData?.description?.error}
          /></div>
          <div className='flex-display' style={{margin:"80px 0"}}>
             <button className='button-style' onClick={()=>onSaveStreamData()}>Save</button>
          </div>
        </div>
    <div className='info-container'>
        <div className='info-wrapper'>
        <div className='col-lg-6'>
            <div className='info-title'>Input Info</div>
            {rowToEdit?.stats?.media_info?.tracks?.length>0 ? getFormattedInfo(rowToEdit?.inputs[0]?.stats?.media_info?.tracks)?.map((track:any)=>{
                return <div className='info-subtitle'>{track}</div>
            }):"-"}
        </div>
        <div className='col-lg-6'>
            <div className='info-title'>Output Info</div>
            {rowToEdit?.stats?.media_info?.tracks?.length>0 ? getFormattedInfo(rowToEdit?.stats?.media_info?.tracks)?.map((track:any)=>{
                return <div className='info-subtitle'>{track}</div>
            }):"-"}
        </div>
        {/* <div className='col-lg-2'>
            
        </div> */}
    </div>
    <div className='info-wrapper' style={{marginTop:"70px"}}>
        <div className='col-lg-5'>
            <div className='info-title'>Uptime</div>
            <div className='info-subtitle'>{rowToEdit?.stats?.lifetime}</div>
        </div>
        <div className='col-lg-5'>
            <div className='info-title'>Status</div>
            <div className='info-subtitle' style={{color:getTextColor(rowToEdit?.stats?.status)}}>{rowToEdit?.stats?.status}</div>
        </div>
        <div className='col-lg-4'>
            <div className='info-title'>Output bitrate</div>
            <div className='info-subtitle'>{rowToEdit?.stats?.output_bitrate}</div>
        </div>
    </div>
    </div>
    <div className='action-container'>
        <div className='action-bar'>
            <div className='notifications' onClick={()=>rowToEdit?.disabled?disableStreamDetails(false):disableStreamDetails(true)}>
                <div className='outer-circle'>
                    <span className={!rowToEdit?.disabled?'inner-circle-left':'inner-circle-right'} />
                </div>
            </div>
            <div className='notifications' onClick={deleteStreamData} style={{cursor:"pointer"}}>
                <img src={deleteIcon} height={"15px"} />
            </div>
            <div className='notifications' onClick={()=>refreshOverviewData()} style={{cursor:"pointer"}}>
                <img src={refreshIcon} height={"15px"} />
            </div>
        </div>
    </div>
    {isDeleteClicked && <DeleteDialog handleClose={handleClose} handleOk={handleOk} open={isDeleteClicked} selectedRow={rowToEdit} />}
    </div>
  )
}

export default OverviewTab;