import React, { useEffect, useState } from 'react'
import "../css/overview.css";
import Card from '../components/Card';
import { Line } from 'rc-progress';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { convertUptime } from '../common-utils';
import { loadData, login, setAlertProps, setOverviewData } from '../redux/actions/streams-action';
import { getUserDetails } from '../redux/actions/api';
import { useNavigate } from 'react-router';

function Overview(){
  const statData = useSelector((state:any) => state.overviewData);
  const userCredentials:any=useSelector((state:any)=>state?.user)
  const dispatch=useDispatch();
  const navigation=useNavigate();
  const userObj:any={userName:{value:userCredentials?.login},password:{value:userCredentials?.password}};

  useEffect(()=>{
    dispatch(loadData(true));
    getUserDetails(userObj).then((data:any)=>{
      if(!data?.message){
        dispatch(setOverviewData(data?.stats));
      }else{
        dispatch(setAlertProps({message:data?.message,severity:data?.severity}));
      }
    });
},[])

useEffect(()=>{
  let interval = setInterval(() => {
    getUserDetails(userObj).then((data:any)=>{
      if(!data?.message){
        dispatch(setOverviewData(data?.stats));
      }else{
        navigation("/")
        dispatch(setAlertProps({message:data?.message,severity:data?.severity}));
      }
    });
  }, 3000);
  return () => {
      clearInterval(interval);
  };
})
 
  const options = {
    chart: {
      type: "pie"
    },
    series: [
      {
        data: [
          {
            y: 50
          }
        ],
        size: '40%',
        innerSize: '75%',
        showInLegend:false,
        dataLabels:false,
        colors:["#00D09C"]
      }
    ]
  };

  let uptime:any= convertUptime(statData?.uptime)?.uptimeText;

  return (
    <React.Fragment>
            <div className='overview-data'>
                <Card title={"Streams"} text={statData?.total_streams||0} type="count"/>
                <Card title={"Online"} text={statData?.online_streams||0} type="count"/>
                <Card title={"Transcoded"} text={statData?.transcoded_streams||0} type="count"/>
                <Card title={"CPU"} type="progress" percent={statData?.cpu_usage||0} titlePlacement={"left"}/>
                <Card title={"Uptime"} text={uptime||0} type="count"/>
                <Card title={"Input"} text={`${(statData?.input_kbit/1000).toFixed(2)||0} mbps`} type="count"/>
                <Card title={"Output"} text={`${(statData?.output_kbit/1000).toFixed(2)||0} mbps`} type="count"/>
                <Card title={"Memory"} type="progress" percent={statData?.memory_usage||0} titlePlacement={"right"}/>
            </div>

        <div className='accelarators'>
            {statData?.transcoder_devices?.length &&  statData?.transcoder_devices?.map((device:any)=>{
                let memUsed:any = ((device?.memUsed/device?.memTotal)*100).toFixed();
             return (device?.gpu_dec||device?.memUsed ||device?.gpu_enc) && <Card title={"Accelarator "+device.id} text="20" type="line-cards">
                <div className="progress-label">
                    <div className="progress-text">DEC</div>
                    <div className='line-progress'><Line percent={device?.gpu_dec||0} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                    <div className="progress-text">{(device?.gpu_dec||0)+"%"}</div>
                </div>
                <div className="progress-label">
                    <div className="progress-text">ENC</div>
                    <div className='line-progress'><Line percent={device?.gpu_enc||0} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"}  strokeColor="#00D09C" /></div>
                    <div className="progress-text">{(device?.gpu_enc||0)+"%"}</div>
                </div>
                <div className="progress-label">
                    <div className="progress-text">MEM</div>
                    <div className='line-progress'><Line percent={memUsed||0} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                    <div className="progress-text">{(memUsed||0)+"%"}</div>
                </div>
            </Card>})}
            {/* <Card title={"Accelarator 02"} text="20" type="line-cards">
                <div className="progress-label">
                <div className="progress-text">DEC</div>
                <div className='line-progress'><Line percent={10} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                <div className="progress-text">{"10%"}</div>
                </div>
                <div className="progress-label">
                <div className="progress-text">ENC</div>
                <div className='line-progress'><Line percent={20} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                <div className="progress-text">{"20%"}</div>
                </div>
                <div className="progress-label">
                <div className="progress-text">MEM</div>
                <div className='line-progress'><Line percent={30} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                <div className="progress-text">{"30%"}</div>
                </div>
            </Card>
            <Card title={"Accelarator 03"} text="20" type="line-cards">
                <div className="progress-label">
                <div className="progress-text">DEC</div>
                <div className='line-progress'><Line percent={10} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                <div className="progress-text">{"10%"}</div>
                </div>
                <div className="progress-label">
                <div className="progress-text">ENC</div>
                <div className='line-progress'><Line percent={20} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"}  strokeColor="#00D09C" /></div>
                <div className="progress-text">{"20%"}</div>
                </div>
                <div className="progress-label">
                <div className="progress-text">MEM</div>
                <div className='line-progress'><Line percent={30} strokeWidth={3} trailWidth={3} trailColor={"#e6fbf6"} strokeColor="#00D09C" /></div>
                <div className="progress-text">{"30%"}</div>
                </div>
            </Card> */}
        </div>
    </React.Fragment>
  )
}

export default Overview;