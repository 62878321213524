import { convertUptime, formatTrackList, getInitialValue, getStatus } from '../../common-utils';
import { ActionTypes } from '../actions/action-types';

const initialState:any = {
  user:{},
  error:"",
  selectedRows:[],
  alertProps:{},
  showAlert:false,
  searchString:"",
  statusFilter:"",
  licenseDetails:{},
  rowToEdit:{},
  videoTracklist:[],
  logoList:[],
  selectedLogo:{}
};

type actionProps = {
  type:string,
  data?:any,
  error?:any  
} 

export default function streamReducer(state = initialState, action:actionProps) {
  switch (action.type) {

    case ActionTypes.SET_INITIAL_STATE:
      return initialState;

    case ActionTypes.LOGIN_DATA: {
      return {...state,error:"",user:action?.data?.edit_auth,overviewData:action?.data?.stats,isLoading:false}
    }

    case ActionTypes.SET_OVERVIEW_DATA:{
      return {...state,error:"",overviewData:action?.data,isLoading:false}
    }

    case ActionTypes.SET_USER_DETAILS: {
      return {...state,error:"",user:action?.data,isLoading:false}
    }

    case ActionTypes.SET_STREAMS_DATA:{
      let list:any[]=action?.data?.streams?.map((stream:any)=>{
        return {...stream,stats:{...stream?.stats,status:getStatus(stream?.stats?.status),lifetime:convertUptime(stream?.stats?.lifetime)?.streamUptime}}
      })
      return {...state,liveStreams:list,isLoading:false}
    }

    case ActionTypes.LOAD_DATA:{
      return {...state,isLoading:action.data};
    }

    case ActionTypes.SET_SEARCH_STRING:{
      return {...state,searchString:action.data};
    }

    case ActionTypes.SET_STATUS_FILTER:{
      console.log(action.data);
      return {...state,statusFilter:action.data};
    }

    case ActionTypes.SET_LICENSE_DATA:{
      return {...state,licenseDetails:action.data.data,error:"",isLoading:false};
    }

    case ActionTypes.SHOW_ERROR:{
      return {...state,error:action.error,isLoading:false};
    }

    case ActionTypes.SET_SELECTED_ROWS:{
      return {...state,selectedRows:action.data};
    }
    
    case ActionTypes.SET_ALERT_PROPS:{
      return {...state,alertProps:action.data}
    }

    case ActionTypes.SET_SHOW_ALERT:{
      return {...state,showAlert:action.data}
    }

    case ActionTypes.ROW_TO_EDIT:{
      return {...state,rowToEdit:action?.data}
    }

    case ActionTypes.UPDATE_OVERVIEW_DATA:{
      let streamData:any={...state.rowToEdit};
      //let index:any=streams?.findIndex((stream:any)=>stream?.name===action?.data?.name)
      console.log(action.data);
      streamData={...action?.data,stats:{...action.data.stats,status:getStatus(action?.data?.stats?.status),lifetime:convertUptime(action?.data?.stats?.lifetime)?.streamUptime}};
      return {...state,rowToEdit:streamData}
    }

    case ActionTypes.SET_VIDEO_LIST:{
      let tracksToAdd:any=[...action.data.tracks];
      action?.data?.isInitial && (tracksToAdd=formatTrackList(tracksToAdd));
  
      return {...state,videoTracklist:tracksToAdd};
    }

    case ActionTypes.SET_LOGO_LIST:{
      return {...state,logoList:action?.data || []}
    }

    case ActionTypes.SET_LOGO_LIST:{
      return {...state,selectedLogo:action?.data || {}}
    }

    default:
      return state
  }
}