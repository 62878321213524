// import { configureStore } from '@reduxjs/toolkit'
// import streamReducer from '../reducers/streams'


// // Automatically adds the thunk middleware and the Redux DevTools extension
// export const store = configureStore({
//   // Automatically calls `combineReducers`
//   reducer: {
//     streams: persistReducer(authPersistConfig,streamReducer)
//   }
// })

import { applyMiddleware, combineReducers,createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import streamReducer from '../reducers/streams';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'streams',
  storage
}

const persistedReducer = persistReducer(persistConfig, streamReducer)

export const store = createStore(persistedReducer,applyMiddleware());
//   {
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk]
// })

export const persistor:any=persistStore(store);

export default store;

