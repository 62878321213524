import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {Alert} from "react-bootstrap";
import InputField from '../components/InputField'
import teamImage from "../images/Team-image.svg"
import edgcoder from "../images/edgcoder.svg"
import "../css/login.css";
import { getLicenseDetails, getUserDetails } from '../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../redux/actions/action-types';
import { useLocation, useNavigate} from "react-router-dom"
import { loadData, login, setAlertProps, setInitialState, setLicenseDetails, setUserDetails, showError } from '../redux/actions/streams-action';

function LoginPage(){
  const [userCredentials,setUserCredentials]:any=useState({userName:{value:''},password:{value:''}});
  const dispatch:any=useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const userDetails:any=useSelector((state:any)=>state?.user);
  const error:any=useSelector((state:any)=>state?.error);
  const licenseDetails:any=useSelector((state:any)=>state?.licenseDetails);
  const userObj:any={userName:{value:userDetails?.login},password:{value:userDetails?.password}};

  const onChangeValue=(e:any)=>{
     let userdetails:any={...userCredentials,[e.target.name]:{...userCredentials[e.target.name],value:e.target.value}};
     setUserCredentials(userdetails);
  }

  useEffect(()=>{
    dispatch(setInitialState());
    dispatch(showError(""));
  },[])

  const onLogin=()=>{
       dispatch(loadData(true));
    if(userCredentials?.userName?.value && userCredentials?.password?.value){
      getLicenseDetails().then((res:any)=>{
        dispatch(loadData(false));
        dispatch(setLicenseDetails(res));
        if(res?.license_status === "expired"){
          dispatch(showError("License expired. Please Contact to Customer support."));
        } else if(res?.license_status === "suspended") {
          dispatch(showError("License suspended. Please Contact to Customer support."));
        }
      });
    }
  }

  useEffect(()=>{
    if(licenseDetails?.license_status==="active"){
      dispatch(loadData(true));
       if(userCredentials?.userName?.value && userCredentials?.password?.value ){
        getUserDetails(userCredentials).then((data:any)=>{
        if(!data?.message){
          dispatch(setUserDetails(data?.edit_auth));
          dispatch(showError(""));
          navigation("/dashboard/overview");
        }else if(data?.message){
          dispatch(loadData(false));
          dispatch(showError("Invalid Username or Password"));
        }
      });
    }
    }else{
      dispatch(loadData(false));
    }
  },[licenseDetails])

  return (
    <div className="wrapper">
        <div className="login-image">
            <img src={teamImage} style={{padding:"15%"}}/>
        </div>
      <div className='login-form'>
        <div className='flex-display'><img src={edgcoder} height={"48px"} width={"231px"}/></div>
        <div className='welcome-text'>Welcome Back</div>
        <div className='unlock-text'>Unlock your Potential</div>
        <div style={{marginTop:"35px"}} className='flex-display'>
          <InputField 
          type={"text"}
          name={"userName"}
          label={'Username'} 
          value={userCredentials?.userName?.value}
          isFloating={true}
          placeholder={''} 
         //error={error} 
          onChange={onChangeValue}/></div>
          <div style={{marginTop:"10px"}} className='flex-display'><InputField 
          type={"password"}
          name={"password"}
          label={'Password'} 
          value={userCredentials?.password?.value||null}
          isFloating={true}
          placeholder={''} 
          //error={error} 
          onChange={onChangeValue}/></div>
          <div className='flex-display' style={{marginTop:"50px"}}>
             <button className='button-style' disabled={!userCredentials?.userName?.value || !userCredentials?.password?.value} onClick={()=>onLogin()}>Login</button>
             {error && <div className='alert-message'>{error}</div>}
          </div>
      </div>
    </div>
  )
}

export default LoginPage;