import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import disneyIcon from "../images/disanyIcon.png";
import starTv from "../images/starTv.png";
import { Backdrop, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, setAlertProps, setLogoList, setSelectedlogo, setVideoList } from '../redux/actions/streams-action';
import { addNewLogo, getLogoList } from '../redux/actions/api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SelectLogo(props:any) {
  const logoList:any=useSelector((state:any)=>state?.logoList);
  const videoTracksList:any=useSelector((state:any)=>state?.videoTracklist);
  const userDetails:any=useSelector((state:any)=>state?.user);
  const dispatch:any=useDispatch();
  const [open, setOpen] = useState(props?.open);
  const [selectedFile,setSelectedFile]=useState({});
  const [isLogoAdd,setIsLogoAdded]=useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFileList,setSelectedFileList]=useState(logoList?.map((logo:any)=>{
    let fileData:any={};
    fileData["content"]= logo?.content;
    fileData["filename"]=logo?.name||"";
    fileData["fileType"]=logo?.content_type;
    return fileData;
  }));

  const handleClose = () => {
    props?.handleOpen(false);
  };

   const handleUpload = (event:any) => {
   // console.log(event);
  };

  const readFileAsync = (file:any,fileName:string) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const formData:any={name:fileName||""};

      reader.onload = (e:any) => {
        // Resolve with the result (e.g., result as text)
        if (typeof e?.target?.result === 'string') {
          // Add the file data to FormData as Base64-encoded string
          formData["content"]=e?.target?.result.split(',')[1];
        } else if (e?.target?.result instanceof ArrayBuffer) {
          // Convert ArrayBuffer to Base64
          let uint:any=new Uint8Array(e.target.result as ArrayBuffer);
          let base64:any = btoa(String.fromCharCode.apply(null,uint));
          formData["content"]=base64;
        }
        resolve(formData);
      };

      reader.onerror = (error) => {
        // Reject with the error
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

 const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>,action:string,fileName?:string) => {
    const file:any = event.target.files?.[0];
    const name:any=action==="change"?fileName:file.name;

    if (file) {
      uploadFile(file,name);
    }
  };

  const uploadFile = async (file: File,fileName?:any) => {
    try {
      setLoading(true);
      
      // Simulate a file upload operation (you can replace this with your actual API call)
      const fileContent:any=await readFileAsync(file,fileName);
        addNewLogo(fileContent,userDetails)?.then((logos:any)=>{
            getLogoList(userDetails)?.then((response:any)=>{
              setLoading(false);
              if(!response?.error?.message){
                  dispatch(setLogoList(response?.logos));
                  dispatch(setAlertProps({message:"The Logo is updated successfully!!",severity:"success"}))
              }else{
                  dispatch(setAlertProps({message:response?.error,severity:"error"}));
              }
            });
          });  
    } catch (error:any) {
      console.error("Error uploading file:", error.message);
    } finally {
      setLoading(false);
    }
  };


  const onSelectImage=(e:any,value:any)=>{
    setSelectedFile(value);
  }

  const confirmSelectedLogo=()=>{
      let tracks:any=[...videoTracksList];
      let index:number=props.index;
      tracks[index]={...tracks[index],logo:{value:selectedFile,error:false},position:{value:"tl",error:false}};
      console.log(tracks);
      dispatch(setVideoList(tracks,false));
      handleClose();
  }

  return (
    <React.Fragment>
      
      <BootstrapDialog
        onClose={handleClose}
        id="select-logo-modal"
        aria-labelledby="select-logo-title"
        open={open}
        maxWidth="sm"
        fullWidth
        style={{padding:"20px"}}
      >
        <DialogTitle id="dialog-title-wrapper">
        <div id="select-logo-title">Select Logo File </div>
        <Button 
          component="label"
          variant="contained"
          onClick={(e:any)=>handleUpload(e)} 
          id='update-btn'>
            Add New
          <TextField
            type="file"
            id="addFileInput"
            label="Select a file"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e:any)=>handleFileChange(e,"add")}
            style={{display:"none"}}
          />
        </Button>
        </DialogTitle>
        
        <DialogContent style={{margin:"0",padding:"0 20px"}}>
        <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue=""
        name="radio-buttons-group"
        onChange={(e:any,value:any)=>onSelectImage(e,value)}
        >
        {selectedFileList.map((fileItem:any)=>{
            return <div className='file-select-wrapper'> 
                <FormControlLabel value={fileItem?.filename} control={<Radio />} 
                  label={<span style={{fontFamily:"LufgaRegular"}}>
                           <img src={`data:${fileItem?.fileType};base64,${fileItem?.content}`} height="65px" width="75px" style={{padding:"0 10px"}}/></span>}/>
                <Button component="label" onClick={(e:any)=>handleUpload(e)} id='change-btn'>Change
                <TextField
                    type="file"
                    id="changeFileInput"
                    label="Select a file"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e:any)=>handleFileChange(e,"change",fileItem?.filename)}
                    style={{display:"none"}}
                  />
                </Button>
             </div>
          })}
        </RadioGroup>
          
        </DialogContent>
        <DialogActions className='dialog-action-wrapper'>
          <Button id={"cancel-btn"}  variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button id={"ok-btn"} onClick={confirmSelectedLogo}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}