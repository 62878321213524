import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import rightArrow from "../images/right-arrow.svg";
import leftArrow from "../images/left-arrow.svg"
import TranscodedSettings from '../components/TranscoderSettings';
import { useSelector } from 'react-redux';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 150,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    height:100,
    transform: 'translateX(110px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(5px)',
      '& .MuiSwitch-thumb:before': {
        position:'absolute',
        left:12,
        width:35,
        backgroundImage: `url(${leftArrow})`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        content:"'Enabled'",
        backgroundColor: '#F4F4F6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#00d09c',
    width: 60,
    height: 60,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${rightArrow})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#F4F4F6',
    borderRadius: 20 / 2,
  },
}));


export default function TranscodedTab() {
const rowData:any=useSelector((state:any)=>state?.rowToEdit)
const [isEnabled,setIsEnabled]=React.useState(false);
const [showSettings,setShowSettings]=React.useState(rowData?.transcoder);
 const toggleSwitch=()=>{
    setIsEnabled(!isEnabled);
    setTimeout(function() {
        setShowSettings(!showSettings);
           }, 1000);
 }

  return (
      <React.Fragment>
        {!showSettings ? <div className='transcode-container'>
            <div className='transcode-wrapper'>
                <input className="switch" id="first-toggle-btn" type="checkbox" checked={isEnabled} onChange={()=>toggleSwitch()}/>
                <label htmlFor="first-toggle-btn">{isEnabled?"Disabled Transcoder":"Enabled Transcoder"}</label>
            </div>  
        </div>:<div id="transcode-settings"><TranscodedSettings/></div>}   
      </React.Fragment>
    
  );
}