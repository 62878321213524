import React, { FC, useEffect, useState } from "react";
import "../css/stream-details.css";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editOverviewDetails, loadData, setAlertProps, showError } from "../redux/actions/streams-action";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OverviewTab from "./OverviewTab";
import InputTab from "./InputTab";
import TranscodedTab from "./TranscodedTab";
import TranscodedSettings from "../components/TranscoderSettings";
import OutputTab from "./OutputTab";
import { getStreamDetails } from "../redux/actions/api";

const StreamDetails:any = () => {
    const [value, setValue] = React.useState('overview');
    let navigation:any=useNavigate();
    let dispatch:any=useDispatch();
    let {id}=useParams();
    const userDetails:any=useSelector((state:any)=>state?.user);

    useEffect(()=>{
        let interval = setInterval(() => {
            getStreamDetails(id,userDetails).then((stream:any)=>{
                dispatch(loadData(false));
                if(!stream?.error){
                  dispatch(editOverviewDetails(stream?.data?.overViewData))
                  //dispatch(setAlertProps({message:"The stream has been updated successfully !!!",severity:"success"}));
                }
                stream?.error && dispatch(setAlertProps({message:"Unable to update the stream",severity:"error"}));
            })
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    })

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (<React.Fragment>
              <TabContext value={value}>
                <div className="tab-wrapper">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider',width:"70%" }}>
                    <TabList onChange={handleChange} centered>
                        <Tab label="Overview" value="overview" />
                        <Tab label="Input" value="input" />
                        <Tab label="Transcode" value="transcode" />
                        <Tab label="Output" value="output" />
                    </TabList>
                    </Box>
                </div>
                <TabPanel value="overview">
                    <OverviewTab/>
                </TabPanel>
                <TabPanel value="input">
                    <InputTab/>
                </TabPanel>
                <TabPanel value="transcode"><TranscodedTab/></TabPanel>
                <TabPanel value="output"><OutputTab/></TabPanel>
              </TabContext>
            </React.Fragment>
    )
}

export default StreamDetails;