
export const getFilterString=(url:any,filter:string)=>{
    switch(filter?.toLowerCase()){
        case "online":
        case "error":
        case "waiting":
         url+=(`&stats.status=${filter?.toLowerCase()}`);
         break;

        case "disabled":
         url+=(`&disabled=true`);
         break;

        case "transcoded":
         url+=(`&transcoder_is_not=null`)
         break;

        default:
          return;
      }

}

export const getTextColor=(status:string)=>{
    switch(status?.toLowerCase()){
        case "online":
            return "#00D09C";
        
        case "error":
            return "#FF5E5B";

        case "waiting":
            return "#FBB41A";
        
        default:
            return "#44475B";
    }
}

export const convertUptime=(uptime:any)=>{
    let years=0,
      months=0,
      days=0,
      hours=0,
      minutes=0,
      seconds=0;

    seconds = Math.floor(uptime / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    days = Math.floor(hours/ 24);
    hours = hours % 24;
    months = Math.floor(days / 30);
    days = days % 30;
    years = Math.floor(months / 12);
    months = months % 12;

    let uptimeText= "",streamUptime="";
    if(years>0) {
        uptimeText=uptimeText?.concat(days+"Y ");
        streamUptime=uptimeText;
    }
    if(days>0){
        uptimeText=uptimeText?.concat(days+"D ");
        streamUptime=uptimeText;
    }
    if(months>0){
        uptimeText=uptimeText?.concat(months+"mo ");
    }
    if(hours>0)  {
        uptimeText=uptimeText?.concat(" "+hours);
        streamUptime=streamUptime?.concat(" "+hours+"h");
    }
    if(minutes>0) {
        uptimeText= hours>0 ?uptimeText?.concat(":"+minutes):uptimeText?.concat(""+minutes);
        streamUptime=hours>0 ?streamUptime?.concat(":"+minutes+"m"):streamUptime?.concat(minutes+"m");
    }
    if(seconds>0) {
        uptimeText=minutes>0?uptimeText?.concat(":"+seconds):uptimeText?.concat(""+seconds);
        streamUptime=hours>0 ?streamUptime?.concat(":"+seconds+"s"):streamUptime?.concat(seconds+"s");
    } 
    
    return {uptimeText,streamUptime};
}

export const getFormattedInfo=(mediainfo:any)=>{
    return mediainfo?.map((track:any)=>{
        return track?.content === "audio" ? track?.track_id+" "+track?.codec+" "+(track?.bitrate?`(${track?.bitrate})`:"") 
        : track?.content ==="video" ? track?.track_id+" "+track?.codec+" "+track?.width+"x"+track?.height+" "+(track?.bitrate?`(${track?.bitrate})`:""):null;
    })
}

export const getStatus=(status:string)=>{
    return status==="running"?"Online":status===undefined?"Disabled":status?.charAt(0).toUpperCase() + status.slice(1);
}

export const setDisabled=(rowData:any)=>{
    return ["running","online"]?.includes((rowData?.stats?.status?.toLowerCase()))
}

export const hex2rgb = (hex:any) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r},${g},${b},0.3)`;
}

export const getInitialValue=(videoTrack:any)=>{
          return{
            track:{isExpanded:false},
            bitrate:{value:videoTrack?.bitrate||"",error:false},
            width:{value:"",error:false},
            height:{value:videoTrack?.height||"",error:false},
            codec:{value:"",error:false},
            bframes:{value:"",error:false},
            sar:{value:"",error:false},
            resize:{value:"",error:false},
            background:{value:"",error:false},
            preset:{value:"",error:false},
            level:{value:"",error:false},
            refs:{value:"",error:false},
            profile:{value:"",error:false},
            position:{value:"",error:false},
            logo:{value:"",error:false}
          }
}

export const getAudioSettings=(data:any)=>{
    return {
        bitrate:{value:(data?.bitrate/1000)||"",error:{}},
        codec:{value:data?.codec||"",error:{}},
        sample_rate:{value:data?.sample_rate||"",error:{}},
        channel:{value:data?.channels||"",error:{}}
      }
}



export const getDeviceOptions=(data:any)=>{
    const devices=data?.map((device:any)=>{
        if(device?.type!=="cpu"){
         return {text:`Accelerator(${device?.id})`,value:`${device?.type+"("+device?.id+")"}`};
        } else{
            return {text:`${device?.name+"("+device?.id+")"}`,value:`${device?.type+"("+device?.id+")"}`};
        }
    });
    return devices;
}

export const getVideoSettings=(data:any,rowData:any)=>{
    let decoder:any=rowData?.transcoder?.decoder;
    let globalData:any=rowData?.transcoder?.global;
    const devices=data?.filter((device:any)=>{
        if(device?.type!=="cpu"){
         return device?.name;
        }
    });
    const deviceName=devices.filter(function(item:any, pos:number) {
        return devices.indexOf(item) == pos;
    });
    return {
        device:{value:`${globalData?.hw+"("+globalData?.deviceid+")"}`,error:{}},
        gopSize:{value:globalData?.gop||"",error:{}},
        fps:{value:globalData?.fps||"",error:{}}
    }
}

export const getDecoderSettings=(decoder:any)=>{
    return {
        deinterlace:{value:decoder?.deinterlace,error:{}},
        cpuDecoding:decoder?.crop?{value:decoder?.crop?.top+":"+decoder?.crop?.left+":"+decoder?.crop?.width+":"+decoder?.crop?.height,error:{}}:{value:"",error:{}},
    }
}

export const isValidUrl=(inputUrl:any)=>{
    // const urlRegex = /^(?:(?:thttps?|ftp|tshttp|publish):\/\/)(?:\S+(?::\S*)?@)?(?:(?!-)[A-Za-z0-9-]{1,63}(?:(?!\.)[A-Za-z0-9-]{1,63})?\.){1,125}(?:(?!-)[A-Za-z0-9-]{2,63}(?:(?!\.)[A-Za-z0-9-]{1,63})?)?(?::\d{1,5})?(?:[/?#]\S*)?$/;
    // return urlRegex.test(inputUrl);
    try {
        new URL(inputUrl);
        return true;
    } catch (error) {
        return false;
    }
}

export const formatTrackList=(trackList:any)=>{
    const data:any=trackList?.map((item:any)=>{
        return{
          track:{isExpanded:false},
          bitrate:{value:item?.bitrate && ((item?.bitrate)/1000)?.toFixed(0)||"",error:false},
          width:{value:item?.size?.width || "",error:false},
          height:{value:item?.size?.height||item?.bitrate||"",error:false},
          codec:{value:item?.codec||"",error:false},
          bframe:{value:item?.bframes || "",error:false},
          sar:{value:(item?.sar?.x||1)+":"+(item?.sar?.y||1),error:false},
          resize:{value:item?.size?.strategy||"",error:false},
          background:{value:item?.size?.background||"",error:false},
          preset:{value:item?.preset||"",error:false},
          level:{value:item?.level||"",error:false},
          refs:{value:item?.refs||"",error:false},
          profile:{value:item?.profile || "",error:false},
          position:{value:item?.logo?.position||"",error:false},
          logo:{value:item?.logo?.path||"",error:false}
        }
  });
  return data;
}

export const getFormattedValue=(inputString:any,indexToReplace:number,maxLength:number)=>{
    let inputValue = inputString.replace(/[^a-zA-Z0-9]/g, '');
    let pattern = new RegExp(`(.{${indexToReplace}})`, 'g');
    let formattedValue = inputValue.replace(pattern, "$1:");
    formattedValue = formattedValue.slice(0, maxLength);
    return formattedValue;
}

export const getFormattedInputDetails=(inputArr:any)=>{
    let data:any=inputArr?.length>0 &&inputArr?.map((input:any)=>{
        return {
            status:{value:input?.stats?.active?"Online":"Offline",error:{}},
            source:{value:input?.url,error:{}}
        }
    });
    return data;
}

export const getFormattedPushList=(pushList:any)=>{
    let data:any=pushList?.length>0 &&pushList?.map((push:any)=>{
        return {
            disabled:push?.disabled,
            url:{value:push?.url||"",error:{}},
            uptime:{value:push?.timeout ||"",error:{}},
            status:{value:push?.disabled?"Disabled":push?.disabled===false?"Online":"" ,error:{}}
        }
    })||[];
    return data;
}

export const isValidUrlWithM4SProtocol=(url:string)=>{
    const customProtocolRegex = /^m4s:\/\/.+/i;
    return url!=="" ? customProtocolRegex.test(url):true;
  }

export const hasValidationError=(data:any)=>{
    // for (const obj of data) {
    //     if (obj?.source?.error?.iserror) {
    //       return true;
    //     }
    
    //     if (obj?.url?.error?.iserror) {
    //       return true;
    //     }
    //   }
    if(Array.isArray(data)){
        data?.forEach((dataItem:any)=>{
            return Object.keys(dataItem)?.forEach((key:any)=>{
                if(dataItem[key]?.error?.iserror){
                    return true;
                }else{
                    return false;
                }
            })
        })
    } else{
        Object.keys(data)?.forEach((key:any)=>{
            if(data[key]?.error?.iserror){
                return true;
            }else{
                return false;
            }
        })
    }
      return false;
}
  