import { FC, useEffect, useState } from 'react';
import "../css/dashboard.css";
import ModalContainer from '../components/ModalContainer';
import errorImg from "../images/error_img.svg";
import InputField from '../components/InputField';
import { userInfo } from 'os';
import { useDispatch, useSelector } from 'react-redux';
import { addNewStream } from '../redux/actions/api';
import { loadData, setAlertProps } from '../redux/actions/streams-action';
import { FormControl, TextField } from '@mui/material';

interface ModalProps {
  showAddModal:boolean,
  onClose:any
}

const AddStreamModal: FC<ModalProps> = ({
  showAddModal,onClose
}) => {  
    const [newStream,setNewStream]:any=useState({name:{value:""},inputType:{value:""},inputValue:{value:""}});
    const userData:any=useSelector((state:any)=>state?.user);
    const dispatch:any=useDispatch();

    const onAddStreamValue=(e:any)=>{
        let streamDetails:any={...newStream,[e.target.name]:{...newStream[e.target.name],value:e.target.value}};
        if(streamDetails?.name?.value?.length>12){
          streamDetails={...streamDetails,name:{...streamDetails?.name,error:{message:"Stream Name should not exceed 12 characters",iserror:true}}};
        }else {
          streamDetails={...streamDetails,name:{...streamDetails?.name,error:{}}};
        }
        setNewStream(streamDetails);
     }

     const isDisabled= !newStream?.name?.value || !newStream?.inputType?.value || !newStream?.inputValue?.value || !newStream?.name?.error;
     
     const onSaveStream=()=>{
       dispatch(loadData(true));
       addNewStream(newStream,userData).then((response:any)=>{
         dispatch(loadData(false));
         onClose("addModal");
         dispatch(setAlertProps({message:response?.message,severity:response?.severity}))
       })
     }

  return (
    <ModalContainer closable={true} customHeaderClass="removeBorder" title='Add Stream' show={showAddModal} handleClose={()=>onClose("addModal")}>
            <div className='body'>
              {/* <div className='stream-name'>
            <InputField 
              type={"text"}
              name={"name"}
              label={'Stream Name'} 
              value={newStream?.name?.value}
              isFloating={true}
              placeholder={''} 
              error={newStream?.name?.error} 
              onChange={onAddStreamValue}/></div> */}
              <div className="col-lg-12">
                <FormControl variant="standard" style={{width:"100%"}}>
                  <TextField 
                    id="name" 
                    name={"name"}
                    label={'Stream Name'} 
                    value={newStream?.name?.value} 
                    error={newStream?.name?.error?.iserror}
                    helperText={newStream?.name?.error?.message}       
                    variant="standard"
                    onChange={(e:any)=>onAddStreamValue(e)}
                  />
                </FormControl>
              </div>
                <div className='row' style={{marginTop:"30px"}}>
                  <div className="col-lg-4">
                    <InputField 
                      type={"select"}
                      name={"inputType"}
                      label={'Input Type'} 
                      options={[{text:"Auto",value:"auto"}]}
                      value={newStream?.inputType?.value}
                      isFloating={true}
                      placeholder={''} 
                      onChange={(e:any)=>onAddStreamValue(e)}
                    />
                  </div>
                  <div className="col-lg-8">
                    <FormControl variant="standard" style={{width:"100%"}}>
                      <TextField 
                        id="name" 
                        name={"inputValue"}
                        label={'Input'} 
                        value={newStream?.inputValue?.value}
                        error={newStream?.inputValue?.error}       
                        variant="standard"
                        onChange={(e:any)=>onAddStreamValue(e)}
                      />
                    </FormControl>
                  </div>
                {/* <div style={{width:"30%"}}><InputField
                type={"select"}
                name={"inputType"}
                label={'Input Type'} 
                options={[{text:"Auto",value:"auto"}]}
                value={newStream?.inputType?.value}
                isFloating={true}
                placeholder={''} 
                onChange={onAddStreamValue}/></div>
                <div className='input-desc'><InputField
                type={"text"}
                name={"inputValue"}
                label={'Input'} 
                value={newStream?.inputValue?.value}
                isFloating={true}
                placeholder={''} 
                onChange={onAddStreamValue}/></div> */}
                </div>
              </div>
              <div className='save-btn'><button className='button-style'onClick={()=>onSaveStream()} disabled={isDisabled}>Save</button></div>
            </ModalContainer>
  );
}

export default AddStreamModal;