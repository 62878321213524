export const ActionTypes= {
    "LOGIN_DATA":"LOGIN_DATA",
    "SET_STREAMS_DATA":"SET_STREAMS_DATA",
    "LOAD_DATA":"LOAD_DATA",
    "SET_LICENSE_DATA":"SET_LICENSE_DATA",
    "SHOW_ERROR":"SHOW_ERROR",
    "SET_SELECTED_ROWS":"SET_SELECTED_ROWS",
    "SET_ALERT_PROPS":"SET_ALERT_PROPS",
    "SET_SHOW_ALERT":"SET_SHOW_ALERT",
    "SET_SEARCH_STRING":"SET_SEARCH_STRING",
    "SET_STATUS_FILTER":"SET_STATUS_FILTER",
    "SET_INITIAL_STATE":"SET_INITIAL_STATE",
    "SET_USER_DETAILS":"SET_USER_DETAILS",
    "SET_OVERVIEW_DATA":"SET_OVERVIEW_DATA",
    "ROW_TO_EDIT":"ROW_TO_EDIT",
    "UPDATE_OVERVIEW_DATA":"UPDATE_OVERVIEW_DATA",
    "SET_VIDEO_LIST":"SET_VIDEO_LIST",
    "SET_LOGO_LIST":"SET_LOGO_LIST",
    "SET_SELECTED_LOGO":"SET_SELECTED_LOGO"
  }