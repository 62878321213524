import React,{FC} from "react";
import "../css/button.css";

type MenuCardProps = {
 iconImg:any,
 text:string,
 onClick?: () => void,
 customClass?:string|undefined,
 iconClass?:string|undefined
};

const MenuCard:FC<MenuCardProps> =({iconImg,text,onClick,iconClass,customClass})=> {
  let newClass:any=iconClass?.length ? "icon "+ iconClass:"icon";
 return <a
  className={`menu-item `+customClass}
  onClick={onClick}>
    <span className={newClass}>{iconImg}</span>
    <span className="menu-text">{text}</span>
    </a>;
};
export default MenuCard;