import React,{FC, useEffect, useState} from "react";
import "../css/outputTab.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import  "../css/outputTab.css";
import plusIcon from "../images/plus-icon.svg";
import { FormControl, FormLabel, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedPushList, getTextColor, hasValidationError, isValidUrlWithM4SProtocol, setDisabled } from "../common-utils";
import deleteIcon from "../images/delete-btn.svg";
import { initialPushValue } from "../constants";
import { loadData, rowToEdit, setAlertProps } from "../redux/actions/streams-action";
import { saveInputDetails } from "../redux/actions/api";


const OutputTab:any =()=> {
  const dataRow:any=useSelector((state:any)=>state?.rowToEdit);
  const userDetails:any=useSelector((state:any)=>state?.user);

  const [pushes,setPushesList]=useState([]);
  const dispatch:any=useDispatch();

  useEffect(()=>{
   setPushesList(getFormattedPushList(dataRow?.pushes))
  },[])
    
  const addNewPush=()=>{
    let data:any=[...pushes]||[];
    data?.push(initialPushValue);
    setPushesList(data);
  }

  const deleteOutput:any=(index:any)=>{
    let data:any=[...pushes];
    data?.splice(index,1);
    setPushesList(data);
  }

  const onChangeValue=(e:any,index:number)=>{
    let data:any=[...pushes];
    data[index]={...data[index],[e.target.name]:{...data[index][e.target.name],value:e.target.value}};
    if(e.target.name==="url" && !isValidUrlWithM4SProtocol(data[index]["url"]?.value)){
        data[index]={...data[index],[e.target.name]:{...data[index][e.target.name],value:e.target.value,error:{iserror:true,message:"Invalid URL"}}};
     }else{
        data[index]={...data[index],[e.target.name]:{...data[index][e.target.name],value:e.target.value,error:{}}};
     }
    setPushesList(data);
  }

  const onSavePushesData=()=>{
    dispatch(loadData(true));
    if(hasValidationError(pushes)){
        dispatch(loadData(false));
        dispatch(setAlertProps({message:"Please resolve the errors and then proceed to save",severity:"error"}));
    }else{
        const formattedPushes:any=pushes?.map((push:any)=>{
            return {
                disabled:push?.disabled,
                timeout:push?.uptime?.value,
                url:push?.url?.value
            }
        });
        const saveData:any={};
        pushes?.length && (saveData["pushes"]=formattedPushes);
       saveInputDetails(saveData,userDetails,dataRow?.name)?.then((data:any)=>{
        dispatch(loadData(false));
        if(data?.error){
            dispatch(setAlertProps({message:data?.error,severity:"error"}));
        } else{
            dispatch(rowToEdit(data?.data));
            dispatch(setAlertProps({message:"Push data updated successfully!!!",severity:"success"}));
        }
       })
    }
}

const disablePush=(index:any)=>{
    let data:any=[...pushes]
    data[index]["disabled"]=!data[index]["disabled"];
    setPushesList(data);
}

  return <div>
            <div className='notifications' onClick={()=>addNewPush()}>
                 <img src={plusIcon} height={"15px"}/>
             </div>
            {pushes?.length>0 && pushes?.map((push:any,index:number)=>{
             return <div className="output-container">
                <div className="output-controls">
                    <FormLabel>Enabled</FormLabel>
                    <div className="flex-display">
                        <div className='notifications' onClick={()=>disablePush(index)}>
                            <div className='outer-circle'>
                              <span className={push?.disabled?'inner-circle-right':'inner-circle-left'} />
                            </div>
                        </div>
                        <div className='notifications' onClick={()=>deleteOutput(index)}>
                            <img src={deleteIcon} height={"15px"} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:"40px"}}>
                    <div className='col-lg-4'>
                        <FormControl variant="standard" style={{width:"100%"}}>
                            <TextField 
                            id="url" 
                            name="url" 
                            error={push?.url?.error?.iserror}
                            helperText={push?.url?.error?.message}
                            value={push?.url?.value} 
                            type="text" 
                            label="URL" 
                            onChange={(e:any)=>onChangeValue(e,index)}      
                            variant="standard" className="text-field-font"/>
                        </FormControl>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className='col-lg-2'>
                        <div className='info-title'>Status</div>
                        <div className='info-subtitle' style={{color:getTextColor(push?.status?.value)}}>{push?.status?.value||"-"}</div>
                    </div>
                    <div className='col-lg-4'>
                        <FormControl variant="standard" style={{width:"100%"}}>
                            <TextField 
                               id="uptime" 
                               name="uptime" 
                               error={push?.uptime?.error?.iserror}
                               value={push?.uptime?.value} 
                               type="number" 
                               label="Uptime" 
                               onChange={(e:any)=>onChangeValue(e,index)}      
                               variant="standard" className="text-field-font"/>
                            </FormControl>
                    </div>
                </div>
            </div>})}
            <div className='output-save-btn'><button className='button-style' onClick={()=>onSavePushesData()} disabled={pushes?.length===0}>Save</button></div>
        </div>

};

export default OutputTab;
