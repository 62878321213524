import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './pages/LoginPage';
import { BrowserRouter as Router,Link,Routes,Route } from "react-router-dom";
import Overview from './pages/Overview';
import LiveStreams from './pages/LiveStreams';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from './redux/actions/action-types';
import { loadData, setShowAlert } from './redux/actions/streams-action';
import AccountSettings from './pages/AccountSettings';
import Dashboard from './pages/Dashboard';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import StreamDetails from './pages/StreamDetails';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  //const [isLoading, setLoading] = useState(true);
  const dispatch:any=useDispatch();
  const isLoading:boolean=useSelector((state:any)=>state?.isLoading);
  const alertProps:any=useSelector((state:any)=>state?.alertProps)||[];
  const showAlert:any=useSelector((state:any)=>state?.showAlert)||false;

  const handleClose=()=>{
    dispatch(setShowAlert(false));
  }
  
  return (
    <div className="App">
        {/*isLoading && <div className="loader-container">
          <div className="loader"></div>
  </div>*/}
   <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={isLoading}
  onClick={handleClose}
>
  <CircularProgress color={"success"} />
</Backdrop>
        {showAlert && <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleClose}
        message={alertProps?.message}
        key={"top right"}
      >
        <Alert severity={alertProps?.severity}  variant={"filled"} id="alert-id">
          {alertProps?.message}
        </Alert>
        </Snackbar>}
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard/" element={<Dashboard/>}>
              <Route path="overview" element={<Overview/>}/>
              <Route path="live-streams" element={<LiveStreams/>}/>
              <Route path="live-streams/details/:id" element={<StreamDetails/>}/>
              <Route path="account-settings" element={<AccountSettings/>}/>
            </Route>
          </Routes>
        </Router>    
    </div>
  );
}

export default App;
