import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import "../css/dashboard.css";
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { doubleArrowsLeft, doubleArrowsRight, navigationItems} from '../constants';
import MenuCard from '../components/MenuCard';
import {Outlet,useNavigate,useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { deleteStream, disableEnableStream, getStreamsList} from '../redux/actions/api';
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import ProfileMenu from './ProfileMenu';
import 'bootstrap/dist/css/bootstrap.css'; 
import Tooltip from 'react-bootstrap/Tooltip'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import edgcoder from "../images/edgcoder.svg";
import notification from "../images/notifications.svg";
import plusIcon from "../images/plus-icon.svg";
import 'rc-dropdown/assets/index.css';
import deleteIcon from "../images/delete-btn.svg";
import { loadData, setAlertProps, setSearchString, setSelectedData, setShowAlert, showError } from '../redux/actions/streams-action';
import ErrorModal from './ErrorModal';
import AddStreamModal from './AddStreamModal';


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const 
AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor:"transparent",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    backgroundColor:"transparent",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Dashboard=()=> {
  const [open, setOpen] = React.useState(true);
  const dispatch:any=useDispatch();
  const [showModal,setShowModal]=useState(false);
  const [showAddModal,setShowAddModal]=useState(false);
  const userCredentials:any=useSelector((state:any)=>state?.user);
  const streamsList:any=useSelector((state:any)=>state?.liveStreams);
  const selectedRows:any[]=useSelector((state:any)=>state?.selectedRows);
  const searchString:string=useSelector((state:any)=>state?.searchString);
  const statusFilter:string=useSelector((state:any)=>state?.statusFilter);
  const alertProps:any=useSelector((state:any)=>state?.alertProps)||[];
  const showAlert:any=useSelector((state:any)=>state?.showAlert);
    
  useEffect(()=>{
    alertProps?.message && dispatch(setShowAlert(!showAlert))  
 },[alertProps]);

  const [screenStatus, setScreenStatus] = useState<string>('Active')
  const [count, setCount] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0);
  const [isDisabledClicked,setIsDisabledClicked]=useState(false);

  useEffect(()=>{
    if(screenStatus==="Idle"){
      dispatch(showError(""));
      navigation("/");
    }
  },[screenStatus])

  const onIdle = () => {
    setScreenStatus('Idle')
  }

  const onActive = () => {
    setScreenStatus('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 300000,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const selectAllandDisabled=()=>{
    setIsDisabledClicked(!isDisabledClicked);
    dispatch(loadData(true));
    const promises:any=streamsList?.map((row:any)=>{
      return disableEnableStream(row,userCredentials,isDisabledClicked).then((res:any)=>{
        return res;
      });
  });

  Promise.all(promises).then((response:any)=>{
    dispatch(loadData(false));
    if(response?.length>0){
      dispatch(setAlertProps({message:isDisabledClicked?"All streams are disabled Successfully !!!":"All streams are enabled Successfully !!!",severity:"success"}));
    }
  });
  dispatch(setSelectedData(streamsList));
  }

const selectAllandDelete=()=>{
  const promises:any=streamsList?.map((row:any)=>{
      return deleteStream(row,userCredentials).then((res:any)=>{
        return res;
      });
  });
  Promise.all(promises).then((response:any)=>{
    if(response?.length>0){
      dispatch(setAlertProps({message:"All streams are deleted Successfully !!!",severity:"success"}));
    }
  });
  dispatch(setSelectedData(streamsList));
}

  const onModalClose=(modalName:string)=>{
    switch(modalName){
      case "errorModal":
        setShowModal(false);
        return;

      case "addModal":
        setShowAddModal(false);
        return;

      default:
        return;
    }
  }

  const onAddBtnClick=()=>{
     if(streamsList?.length>120){
      setShowModal(true);
     } else{
      setShowAddModal(true);
     }
  }

  const navigation = useNavigate();
  const location = useLocation();

  const onMenuClick=(url:string)=>{
    navigation(url);
  }

    const getSearchString=(e:any)=>{
      dispatch(setSearchString(e.target.value));
    }


  return (
      <Box sx={{ display: 'flex' }}>
        <Drawer variant="permanent" open={open}>
          <List component="nav" className="nav-class">
            <div>
                <ListItemButton>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText/>
                </ListItemButton>
        
                {navigationItems?.map((item:any)=>{
                        return<ListItemButton id={item?.id} style={{padding:"0 0 0px 2px",marginTop:"10px"}}><MenuCard text={item.name}
                            iconImg={item.svgImg}
                            customClass={
                                location.pathname.includes(item.url) ? "menu-item_active" : ""
                            }
                            onClick={()=>onMenuClick(item.url)}/></ListItemButton>
                    })}
            </div>
            <ListItemButton  onClick={()=>setOpen(!open)} style={{padding:"0 0 0px 2px",maxHeight:"53px"}}><MenuCard iconImg={open?doubleArrowsLeft:doubleArrowsRight} iconClass='custom-icon' text="Collapse"/></ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#fff",
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <div className="content-display">
        <div className="navbar-container">
        <div style={{paddingLeft:"15px"}}><img src={edgcoder} height={"30px"}/></div>
        {location?.pathname.includes("live-streams") && !location?.pathname.includes("live-streams/details")&& 
        <div className='search-container'>
          <div>
             <input className='search'
                 type="search" 
                 value={searchString}
                 placeholder='Search' 
                 onChange={(e)=>getSearchString(e)}
                 /></div>
          <OverlayTrigger
      delay={{ hide: 450, show: 300 }} 
      overlay={(props) => ( 
        <Tooltip {...props}> 
          Add new Stream 
        </Tooltip> 
      )} 
      placement="bottom"
    >
      <div className='notifications' onClick={()=>onAddBtnClick()}><img src={plusIcon} height={"15px"}/></div></OverlayTrigger>
    <MultiSelectDropdown />
    {selectedRows?.length >0 && 
       <><OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Select All & {isDisabledClicked?"Enable Streams":"Disable Streams"}
                    </Tooltip>
                  )}
                  placement="bottom">
                  <div className='notifications' onClick={selectAllandDisabled}>
                    <div className='outer-circle'>
                      <span className={isDisabledClicked?'inner-circle-left':'inner-circle-right'} />
                    </div>
                  </div>
                </OverlayTrigger><OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Select All & Delete Streams
                    </Tooltip>
                  )}
                  placement="bottom">
                    <div className='notifications'  onClick={selectAllandDelete}>
                      <img src={deleteIcon} height={"15px"} />
                    </div>
                  </OverlayTrigger></>}
        </div>}
        <div className="profile-details">
          <div className='notifications'><img src={notification} height={"15px"}/></div>
          <ProfileMenu/>
        </div>
        </div>
    </div>
           <Outlet/>
           {showModal && <ErrorModal onClose={onModalClose} showModal={showModal}/>}
           {showAddModal && <AddStreamModal showAddModal={showAddModal} onClose={onModalClose}/>}
          </Container>
        </Box>
      </Box>
  );
}
export default Dashboard;