import React, { useEffect, useState } from 'react'
import "../css/stream-details.css";
import deleteIcon from "../images/delete-btn.svg";
import { saveInputDetails} from '../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom"
import { loadData, rowToEdit, setAlertProps} from '../redux/actions/streams-action';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { streamInitialstate } from '../constants';
import infoicon from "../images/Info-icon.svg";
import { getFormattedInputDetails, getTextColor, hex2rgb, isValidUrl, setDisabled } from '../common-utils';
import { Button, FormControl, InputAdornment, Popover, TextField, Typography, lighten } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const InputTab=()=>{
  const dataRow:any=useSelector((state:any)=>state?.rowToEdit);
  const [inputDetails,setInputDetails]:any=useState([]);
  const [fallbackVideo,setFallbackVideo]:any=useState({value:dataRow?.backup?.file});
  const [newSourceURL,setNewSource]:any=useState({
    status:{value:"Offline",error:{}},
    source:{value:"",error:{}}
   });

  useEffect(()=>{
    setInputDetails(getFormattedInputDetails(dataRow?.inputs));
  },[])

  const dispatch:any=useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const userDetails:any=useSelector((state:any)=>state?.user);
  
  const onChangeValue=(e:any,index?:any)=>{
    if(e.target?.name==="fallback"){
        setFallbackVideo({value:e.target?.value})
    }else{
        let inputData:any=[...inputDetails];
     inputData[index]={...inputDetails[index],[e.target.name]:{...inputDetails[index][e.target.name],value:e.target.value}};
     if(e.target.name==="source" && !isValidUrl(inputData[index]["source"]?.value)){
        inputData[index]={...inputDetails[index],[e.target.name]:{...inputDetails[index][e.target.name],value:e.target.value,error:{iserror:true,message:"This URL does not match RFC3986"}}};
     }else{
        inputData[index]={...inputDetails[index],[e.target.name]:{...inputDetails[index][e.target.name],value:e.target.value,error:{}}};
     }
     setInputDetails(inputData);
    }
  }

   const addNewSourceValue=(e:any)=>{
    const newObject:any={...newSourceURL};
    newObject["source"]["value"]=e.target.value;
    setNewSource(newObject);
   }

   const addNewRow=()=>{
       let data:any=[...inputDetails];
       if(!isValidUrl(newSourceURL["source"]?.value)){
        newSourceURL["source"]["error"]={iserror:true,message:"This URL does not match RFC3986"};
       }
       newSourceURL?.source?.value!=="" && data?.push(newSourceURL);
       setNewSource({
        status:{value:"Offline",error:{}},
        source:{value:"",error:{}}
       });
       setInputDetails(data);
    }

    const removeRows=()=>{
        let data=[...inputDetails];
        const index:any= data?.findIndex((input:any)=>input?.source?.value==="");
           if(index!==-1){data?.splice(index,1);}
           //data?.push(newObject);
           setInputDetails(data);
        }

        const deleteInput=(index:number)=>{
            let data=[...inputDetails];
               if(index!==-1){data?.splice(index,1);}
               setInputDetails(data);
            }

    const onSaveInputData=()=>{
        //const isError:any=false;
        dispatch(loadData(true));
        if(inputDetails?.find((input:any)=>input?.error?.iserror)){
            dispatch(loadData(false));
            dispatch(setAlertProps({message:"Please resolve the errors and then proceed to save",severity:"error"}));
        }else{
            const formattedInputs:any=inputDetails?.map((input:any)=>{return {url:input?.source?.value}});
            const saveData:any={};
            fallbackVideo?.value && (saveData["backup"]={file:fallbackVideo?.value})
            inputDetails?.length && (saveData["inputs"]=formattedInputs);
           saveInputDetails(saveData,userDetails,dataRow?.name)?.then((data:any)=>{
            dispatch(loadData(false));
            if(data?.error){
                dispatch(setAlertProps({message:data?.error,severity:"error"}));
            } else{
                dispatch(rowToEdit(data?.data));
                dispatch(setAlertProps({message:"Input data updated successfully!!!",severity:"success"}));
            }
           })
        }
    }
  return (<React.Fragment><div className='row flex-display' style={{marginTop: "75px",alignItems: "center"}}>
    {/* <Breadcrumb id="page-breadcrumb">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="#" active>
        Account Settings
      </Breadcrumb.Item>
    </Breadcrumb> */}
        <div className="flex-display" style={{justifyContent:"space-between"}}>
           <div className="col-lg-6"> 
           {inputDetails?.length && 
             inputDetails?.map((input:any,index:number)=>{
              return <div className='flex-display' style={{marginTop:"30px",alignItems:"center"}}>
                <div className='col-lg-4' style={{marginRight:"15px"}}>
                    <div className='status-badge' style={{background: hex2rgb(getTextColor(input?.status?.value)),color:getTextColor(input?.status?.value)}}>
                        <span style={{color:getTextColor(input?.status?.value),opacity:"1"}}>{input?.status?.value}</span>
                    </div>
                </div>
                <div className='col-lg-7'>
                        <FormControl variant="standard" style={{width:"100%"}}>
                            <TextField 
                                id="source" 
                                type={"text"}
                                name={"source"}
                                label={'Source'} 
                                value={input?.source?.value}
                                error={input?.source?.error?.iserror}
                                helperText={input?.source?.error?.message}
                                onChange={(e:any)=>onChangeValue(e,index)}      
                                variant="standard" 
                                className="text-field-font"
                                // onFocus={addNewRow}
                                onBlur={()=>removeRows()}
                               />
                        </FormControl>
                </div>
                <div className='col-lg-1'>
                    <div className='notifications' onClick={()=>deleteInput(index)}>
                      <img src={deleteIcon} height={"15px"}/>
                    </div>
                </div>
            </div>})
            }</div>
            <div className='col-lg-5 fallback-input' style={{marginTop:"40px",alignItems:"center"}}>
                <FormControl variant="standard">
                    <TextField 
                        type={"text"}
                        name={"fallback"}
                        label={'Fallback Video'} 
                        value={fallbackVideo?.value}
                       // error={ifallback?.error}
                        onChange={(e:any)=>onChangeValue(e)}      
                        variant="standard" 
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <PopupState variant="popover" popupId="info-popup">
                                    {(popupState:any) => (
                                        <div>
                                        <Button variant="text" {...bindTrigger(popupState)}>
                                        <img src={infoicon}/>
                                        </Button>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}>Path to the backup file in a VOD location on the server (not on the local disk!). The backup file is played to fill in a time interval when the source is down.</Typography>
                                        </Popover>
                                        </div>
                                    )}
                                    </PopupState>

                            </InputAdornment>
                            ),
                        }}
                        
                        className="text-field-font"/>
                </FormControl>
            </div>
            {/* <div className='col-lg-1'>
                <div className='push-btn flex-display' style={{width:"145px"}}>
                    <div>Push</div>
                    <div className='outer-circle'>
                        <span className={setDisabled(rowToEdit)?'inner-circle-left':'inner-circle-right'} />
                    </div>
                    <div><img src={infoicon} style={{verticalAlign: "sub"}}/></div>
                </div>
            </div> */}
        </div>
        <div className='col-lg-12' style={{margin:"30px 5px 0"}}>
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField 
                  id="source" 
                  type={"text"}
                  name={"newSource"}
                  label={'New URL'} 
                  value={newSourceURL?.source?.value}
                //   error={input?.source?.error?.iserror}
                //   helperText={input?.source?.error?.message}
                  onChange={(e:any)=>addNewSourceValue(e)}      
                  variant="standard" 
                  className="text-field-font"
                  onBlur={()=>addNewRow()}/>
          </FormControl>                    
        </div>
    </div>
    <div className='flex-display' style={{margin:"80px 0"}}>
        <button className='button-style' 
        //</div>disabled={!inputDetails?.source?.value || !inputDetails?.fallback?.value} 
        onClick={()=>onSaveInputData()}
        >Save</button>
    </div>
    </React.Fragment>
  )
}

export default InputTab;