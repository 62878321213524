import React, { useEffect, useState } from 'react'
import "../css/videoTrack.css"
import { Button, FormControl, InputAdornment,TextField} from '@mui/material';
import { bFramesOptions, heightOptions, interlaceOptions, levelOptions, positionOptions, presetOptions, profileOptions, refsOptions, resizeOptions, videoCodecOptions } from '../constants';
import InputField from './InputField';
import LaunchIcon from '@mui/icons-material/Launch';
import SelectLogo from './SelectLogoModal';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertProps, setLogoList, setVideoList } from '../redux/actions/streams-action';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ChromePicker } from 'react-color';
import { getLogoList } from '../redux/actions/api';
import { getFormattedValue } from '../common-utils';

const VideoTrack:any= () => {
    const userDetails:any=useSelector((state:any)=>state?.user);
    const videoTracksList:any=useSelector((state:any)=>state?.videoTracklist);
    const [showPalette,setShowPalette]=useState(false);
    const [openSelectModal,setOpenSelectModal]=useState(false);
    const dispatch:any=useDispatch();

    React.useEffect(()=>{
        getLogoList(userDetails)?.then((response:any)=>{
            if(!response?.error?.message){
                dispatch(setLogoList(response?.logos));
            }else{
                dispatch(setAlertProps({message:response?.error,severity:"error"}));
            }
        });
    },[]);    

    const onChange=(e:any,index:number)=>{
       let tracks:any=[...videoTracksList];
       tracks[index]={...tracks[index],[e?.target?.name]:{...tracks[index][e.target.name],value:e?.target?.value}};
       if(e?.target?.name === "sar" ){
            let outputString:any=getFormattedValue(tracks[index]["sar"]["value"],3,7);
            if(tracks[index]["sar"]?.value?.length !==7){
            tracks[index]={...tracks[index],[e?.target?.name]:{...tracks[index][e.target.name],value:outputString,error:{iserror:true,message:"Specify all settings or nothing"}}}
            }else if(tracks[index]["sar"]?.value?.length ===7){
            tracks[index]={...tracks[index],[e?.target?.name]:{...tracks[index][e.target.name],value:outputString,error:false,helperText:""}}
            }
        }
        if(e.target.name==="bitrate"){
          if(e?.target?.value===""){
            tracks[index]={...tracks[index],[e?.target?.name]:{...tracks[index][e.target.name],value:e.target.value,error:{iserror:true,message:"Bitrate is required"}}}
          }else{
            tracks[index]={...tracks[index],[e?.target?.name]:{...tracks[index][e.target.name],value:e.target.value,error:{}}}
          }
        }
       console.log(tracks[index]);
       dispatch(setVideoList(tracks,false));
    }
    
    const onChangeBackground=(colorCode:any,index:number)=>{
            let tracks:any=[...videoTracksList];
            tracks[index]={...tracks[index],background:{value:colorCode?.hex,error:false}};

            dispatch(setVideoList(tracks,false));
            //setShowPalette(false);
         };

     const setModal=(isOpen:any)=>{
        setOpenSelectModal(isOpen);
    }
    
    const onExpanded=(index:any)=>{
        let tracks:any=[...videoTracksList];
        tracks[index]["track"]={isExpanded:!tracks[index]?.track?.isExpanded};
        dispatch(setVideoList(tracks,false));
    }

    const removeTrack=(e:any,index:any)=>{
        e.stopPropagation();
        let tracks:any=[...videoTracksList];
        tracks?.splice(index,1);
        dispatch(setVideoList(tracks,false));
    }

    const duplicateTrack=(e:any,video:any,index:any)=>{
        video["track"]={...video?.tracks,isExpanded:false};
        e.stopPropagation();
        let tracks:any=[...videoTracksList];
        tracks?.push(video);
        console.log("index",index,"tracks",tracks);
        dispatch(setVideoList(tracks,false));
    }

    return(
        videoTracksList?.length >0 ? videoTracksList?.map((video:any,index:number)=>{
       return(
       <div className='video-track-wrapper'>
            <div className="video-select-wrapper" onClick={()=>onExpanded(index)}>
                <div className='flex-display' style={{alignItems:"center"}}>
                    <span className="card-title">Track {index+1}</span>
                    <Button id="toggle-arrow">{video?.track?.isExpanded?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}</Button>
                </div>
                <div className="video-dd-track">
                {index+1 > 1 && <Button id={"duplicate-btn"} onClick={(e:any)=>duplicateTrack(e,video,index)}>Duplicate</Button>}
                <Button color="error" onClick={(e:any)=>removeTrack(e,index)}>Remove</Button>
                </div>
            </div>
            <div>
            <div className='row' style={{padding:"15px 10px"}}>
                <div className='col-lg-3'>
                    <FormControl variant="standard" style={{width:"100%"}}>
                        <TextField 
                        id="bitrate" 
                        name="bitrate"
                        error={video?.bitrate?.error?.iserror}
                        helperText={video?.bitrate?.error?.message}
                        value={video?.bitrate?.value} 
                        type="number" 
                        label="Bitrate" 
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <span style={{fontFamily:"LufgaRegular"}}>k</span>
                            </InputAdornment>
                            ),
                        }}
                        onChange={(e:any)=>onChange(e,index)}      
                        variant="standard" className="text-field-font"/>
                    </FormControl>
                </div>
                <div className='col-lg-3'>
                    <FormControl variant="standard" style={{width:"100%"}}>
                        <TextField 
                        id="width" 
                        name="width" 
                        error={video?.width?.error} 
                        type="number" 
                        label="Width"
                        value={video?.width?.value}  
                        onChange={(e:React.ChangeEvent<HTMLInputElement>)=>onChange(e,index)}      
                        variant="standard"/>
                    </FormControl>
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"height"}
                    label={'Height'} 
                    value={video?.height?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.height?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={heightOptions} width={"100%"}/>
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"codec"}
                    label={'Codec'} 
                    value={video?.codec?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.codec?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={videoCodecOptions} width={"100%"}/>
                </div>
            </div>
            <div className='row' style={{padding:"15px 10px"}}>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"bframe"}
                    label={'BFrame'} 
                    value={video?.bframe?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.bframe?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={bFramesOptions} width={"100%"}/>
                </div>
                <div className='col-lg-3'>
                    <FormControl variant="standard" style={{width:"100%"}}>
                        <TextField 
                        id="sar" 
                        name="sar" 
                        error={video?.sar?.error?.iserror}
                        helperText={video?.sar?.error?.message} 
                        type="text" 
                        label="SAR"
                        placeholder='1:1'
                        value={video?.sar?.value}  
                        onChange={(e:React.ChangeEvent<HTMLInputElement>)=>onChange(e,index)}      
                        variant="standard"
                        />
                    </FormControl>
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"resize"}
                    label={'Resize'} 
                    value={video?.resize?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.resize?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={resizeOptions} width={"100%"}/>
                </div>
                {video?.resize?.value === "fit"&&(<div className='col-lg-3'>
                <FormControl variant="standard" style={{width:"100%"}}>
                  <TextField
                    type='text'
                    id="background"
                    name='background'
                    label="Background"
                    defaultValue={'#000000'}
                    value={video?.background?.value}
                    //convert={"number"}
                    onChange={(color:any) => onChange(color,index)}
                    onFocus={()=>setShowPalette(!showPalette)}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <div style={{backgroundColor:video?.background?.value}} className='color-picker-adornment'/>
                        </InputAdornment>
                        )
                    }}
                    variant="standard" className="text-field-font backgroundInput"/>
                    {showPalette && <div style={{inset:"0",height: "0",zIndex:"100"}}><ChromePicker
                        onChangeComplete={()=>setShowPalette(false)}
                        onChange={(color:any)=>onChangeBackground(color,index)}
                        disableAlpha={true}
                        color={video?.background?.value}
                        /></div>}
                    </FormControl>
                </div>)}
            </div>
            <div className='fields-content' style={{display:video?.track?.isExpanded?"block":"none",opacity:video?.track?.isExpanded?"1":"0"}}><div className='row' style={{padding:"15px 10px"}}>
                <div className='col-lg-3'>
                <InputField 
                    type={"select"}
                    name={"profile"}
                    label={'Profile'} 
                    value={video?.profile?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.profile?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={profileOptions} width={"100%"}/>
                </div>
                <div className='col-lg-3'>
                <InputField 
                    type={"select"}
                    name={"interlace"}
                    label={'Interlace'} 
                    value={video?.interlace?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.interlace?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={interlaceOptions}width={"100%"} />
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"preset"}
                    label={'Preset'} 
                    value={video?.preset?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.preset?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={presetOptions} width={"100%"}/>
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"refs"}
                    label={'Refs'} 
                    value={video?.refs?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.refs?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={refsOptions} width={"100%"}/>
                </div>
            </div>
            <div className='row' style={{padding:"15px 10px"}}>
                <div className='col-lg-3'>
                <InputField 
                    type={"select"}
                    name={"level"}
                    label={'Level'} 
                    value={video?.level?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.level?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={levelOptions} width={"100%"}/>
                </div>
                <div className='col-lg-6'>
                    <FormControl variant="standard" style={{width:"100%"}}>
                        <TextField 
                        id="logo" 
                        name="logo" 
                        error={video?.logo?.error}
                        value={video?.logo?.value} 
                        type="text" 
                        label="Select Logo" 
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <span onClick={()=>setModal(true)} style={{cursor:"pointer"}}><LaunchIcon/></span>
                            </InputAdornment>
                            ),
                        }}
                        //onClick={()=>setModal(true)}
                        //onFocus={(e:any)=>setModal(true)}      
                        variant="standard"/>
                    </FormControl>
                </div>
                <div className='col-lg-3'>
                    <InputField 
                    type={"select"}
                    name={"position"}
                    label={'Position'} 
                    value={video?.position?.value}
                    isFloating={true}
                    placeholder={''} 
                    error={video?.refs?.error} 
                    onChange={(e:any)=>onChange(e,index)} 
                    options={positionOptions}
                    width={"100%"}/>
                </div>
            </div></div>
         </div>
    {openSelectModal && <SelectLogo open={openSelectModal} handleOpen={setModal}  index={index}/>}
        </div>)}):<React.Fragment></React.Fragment>
    );
}

export default VideoTrack;