import { FC, useEffect, useState } from "react";
import "../css/multiSelect.css";
import filterIcon from "../images/filter-icon.svg";
import 'bootstrap/dist/css/bootstrap.css'; 
import Tooltip from 'react-bootstrap/Tooltip'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useOutsideClick } from "../interfaces/customHook";
import { useDispatch, useSelector } from "react-redux";
import { setStatusFilters } from "../redux/actions/streams-action";
import { menuOptions } from "../constants";
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const MultiSelectDropdown:FC<any> = () => {
    let [showMenu,setShowMenu]=useState(false);
    const statusFilter:any=useSelector((state:any)=>state?.statusFilter);
    let statusArr:any=[];
    statusFilter!=="" && statusArr.push(statusFilter)
    const [selectedOption, setSelectedOption]:any = useState(statusArr);
    const dispatch=useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOption([]);
  };

    useEffect(()=>{
      open &&setSelectedOption([statusFilter]);
    },[open])

    useEffect(()=>{
        setAnchorEl(null);
    },[statusFilter]);

    const confirmSelection=()=>{
        dispatch(setStatusFilters(selectedOption[0]));
    }

    const toggleOption = (e:any,id:string) => {
        setSelectedOption((prevState:any)=>{
            if(prevState?.includes(id)){
          return [];
        }else{
          return [id];
        }
        })
    }

  return (
    <div className="font-family">
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{padding:0,minWidth:0}}
      >
        <OverlayTrigger delay={{ hide: 450, show: 300 }} 
      overlay={(props) =>{ 
        return ( 
        <Tooltip {...props}> 
          Filter Streams 
        </Tooltip> 
      )}}
      placement="bottom">
        <div>
                <div className="selected-count">{selectedOption.length}</div>
                <div className='notifications'><img src={filterIcon} height={"15px"}/></div>
            </div></OverlayTrigger>
      </Button>
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuOptions?.map((option:any)=>{
         const isSelected = selectedOption.includes(option.id);
        return <MenuItem onClick={(e:any)=>toggleOption(e,option.id)} className="menu-container">
               <input type="checkbox" checked={isSelected} className="c-multi-select-dropdown__option-checkbox"/>
               <span className="menu-title">{option.title}</span>
        </MenuItem>
        })}
         <button className="btn-ok" onClick={confirmSelection}>OK</button>
      </Menu>
    </div>
  )

      //      <div className="c-multi-select-dropdown">
      //       <OverlayTrigger delay={{ hide: 450, show: 300 }} 
      // overlay={(props) =>{ 
      //   return ( 
      //   <Tooltip {...props}> 
      //     Filter Streams 
      //   </Tooltip> 
      // )}}
      // placement="bottom">
      //   <div className="c-multi-select-dropdown__selected">
      //           <div className="selected-count">{selectedOption.length}</div>
      //           <div className='notifications' onClick={()=>setShowMenu(!showMenu)}><img src={filterIcon} height={"15px"}/></div>
      //       </div></OverlayTrigger>
      //       <ul ref={ref} className="c-multi-select-dropdown__options" style={{display:showMenu?"block":"none"}}>
      //           {menuOptions.map(option =>{
      //               const isSelected = selectedOption.includes(option.id);

      //               return (
      //                   <li className="c-multi-select-dropdown__option" onClick={(e:any) => toggleOption(e,option.id)}>
      //                       <input type="checkbox" checked={isSelected} className="c-multi-select-dropdown__option-checkbox"/>
      //                       <span>{option.title}</span>
      //                   </li>
      //               )
      //           })}
      //           <button className="btn-ok" onClick={confirmSelection}>OK</button>
      //       </ul>
      //   </div>);
}

export default MultiSelectDropdown;