import { api_url} from "../../constants";

//const dispatch:any=useDispatch();
export const getUserDetails=(credentials:any)=>{    
    return fetch(api_url?.user_details_api, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${credentials?.userName?.value}:${credentials?.password?.value}`)}`,
          "Content-Type":"text/json"
        }),
      }).then((response:any) => {
         return response.json();
      }).then((data:any) => {
        return data?.error ? {message:"Authorization Failed",severity:"error"}:data;
     }).catch((error:any)=>{
        return {message:"Unable to find the data",severity:"error"};
     });
}

export const getLogoList=(credentials:any)=>{   
   return fetch(api_url?.get_logos_api, {
       headers: new Headers({
         "Authorization": `Basic ${btoa(`${credentials?.login}:${credentials?.password}`)}`,
         "Content-Type":"text/json"
       }),
     }).then((response:any) => {
        return response.json();
     }).then((data:any) => {
       return data?.error ? {message:"No Logos Available",severity:"error"}:data;
    }).catch((error:any)=>{
       return {message:"Unable to find the data",severity:"error"};
    });
}

export const getStreamsList=(userDetails:any,pageLimit:number,searchString:string,filter:string)=>{
    let url:string=`${api_url?.stream_api}?limit=${pageLimit}`;
    if(searchString) url+=(`&q=${searchString}`);
    if(filter) {
      ["Error","Online","Waiting","error","online","waiting","running"]?.includes(filter) && (url+=(`&stats.status=${filter?.toLowerCase()}`));
      filter==="disabled"&& (url+=(`&disabled=true`));
      filter==="transcoded"&& (url+=(`&transcoder_is_not=null`))
    }
    return fetch(url, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${userDetails?.login}:${userDetails?.password}`)}`,
          "Content-Type":"text/json"
        }),
      }).then((response:any) => {
         return response.json();
      }).then((data:any) => {
        return data;
     })
}

export const addNewLogo=(file:any,user:any)=>{
   let url:any=`${api_url?.get_logos_api}/${file?.name}`;
   return fetch(url, {
      headers: new Headers({
        "Authorization": `Basic ${btoa(`${user?.login}:${user?.password}`)}`,
        "Content-Type":"text/json"
      }),
      method:"PUT",
      body:JSON.stringify(file)
    }).then((response:any) => {
       return response.json();
    }).then((data:any) => {
      return {message:"The logo has been added Successfully!!!",severity:"success",data};
   }).catch((error:any)=>{
      return {message:"Unable to find the data",severity:"error"};
   });

}

export const addNewStream=(streamDetails:any,credentials:any)=>{
   return fetch(api_url?.base_action_api+`/${encodeURIComponent(streamDetails?.name?.value)}`, {
      headers: new Headers({
        "Authorization": `Basic ${btoa(`${credentials?.login}:${credentials?.password}`)}`,
        "Content-Type":"text/json"
      }),
      method:"PUT",
      body:JSON.stringify({name:streamDetails?.name?.value,inputs:[{[streamDetails?.inputtype?.value]:streamDetails?.inputValue?.value}]})
    }).then((response:any) => {
       return response.json();
    }).then((data:any) => {
      return {message:"Stream has been added Successfully!!!",severity:"success",data};
   }).catch((error:any)=>{
      return {message:"Unable to find the data",severity:"error"};
   });
}

export const getLicenseDetails=()=>{
    return fetch(api_url?.license_api)
    .then(response => response.json())
    .then(data =>{
        return data;
    })
    .catch(error => error);
 }

 export const refreshRow=(selectedRow:any,userDetails:any)=>{
    const url:string=`${api_url?.base_action_api}/${selectedRow?.name}/stop`
    return fetch(url, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${userDetails?.login}:${userDetails?.password}`)}`,
          "Content-Type":"text/json"
        }),
        method:"POST"
      }).then((response:any) => {
         if(response?.status === 204) {
            return {
            data:{status:200,message:"The stream has been successfully stopped!!!",severity:"success"}
           }
        };
      }).catch((error:any) => {
        return {
            data:{status:400,message:"Unable to stop the stream",severity:"error"}
           }
     })
 }

 export const getStreamDetails=(streamName:any,userDetails:any)=>{
   const url:string=`${api_url?.base_action_api}/${encodeURIComponent(streamName)}`
    return fetch(url, {
        headers: new Headers({
          "Authorization": `Bearer ${btoa(`${userDetails?.login}:${userDetails?.password}`)}`,
          "Content-Type":"application/json",
          "Accept":"application/json,text/plain,*/*"
        }),
      }).then((response:any) => {
         if(response?.status === 200) {
            return response.json();
        };
      }).then((dataObj:any)=>{
         return {
            data:{status:200,message:"The stream is updated successfully !!!",severity:"success",overViewData:dataObj}
           };
      }).catch((error:any) => {
         console.log(error);
      //   return {
      //       data:{status:400,message:"Unable to update the stream",severity:"error"}
      //      }
     })
 }

 export const deleteStream=(selectedRow:any,userDetails:any)=>{
    const url:string=`${api_url?.base_action_api}/${encodeURIComponent(selectedRow?.name)}`
    return fetch(url, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${userDetails?.login}:${userDetails?.password}`)}`,
          "Content-Type":"text/json"
        }),
        method:"DELETE"
      }).then((response:any) => {
         if(response?.status === 204) {
            return {
            data:{status:200,message:"The stream has been successfully deleted!!!",severity:"success"}
           }
        };
      }).catch((error:any) => {
        return {
            data:{status:400,message:"Unable to delete the stream",severity:"error"}
           }
     })
 }

 export const disableEnableStream=(selectedRow:any,userDetails:any,isDisabled:boolean)=>{
    const url:string=`${api_url?.base_action_api}/${encodeURIComponent(selectedRow?.name)}`
    return fetch(url, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${userDetails?.login}:${userDetails?.password}`)}`,
          "Content-Type":"text/json"
        }),
        method:"PUT",
        body:JSON.stringify({disabled:isDisabled||false})
      }).then((response:any) => {
         if(response?.status === 200) {
            return {
            data:{status:200,message:"The stream has been successfully saved!!!",severity:"success"}
           }
        };
      }).catch((error:any) => {
        return {
            data:{status:400,message:"Unable to save the stream",severity:"error"}
           }
     })
 }

 export const updatePassword=(userCredentials:any,newUserData:any)=>{
    return fetch(api_url?.user_details_api, {
        method:"PUT",
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${userCredentials?.login}:${userCredentials?.password}`)}`,
          "Content-Type":"text/json"
        }),
        body:JSON.stringify(
            {
               "edit_auth":{
                  "login":newUserData?.login?.value,
                  "password":newUserData?.password?.value
               }
            }        
            )
      }).then((response:any) => {
         return response.json();
      }).then((data:any) => {
        return {status:200,data};
     }).catch((error:any)=>{
        return {message:"Unable to find the data",severity:"error"};
     });
 }

 export const editStreamDetails=(streamData:any,userData:any)=>{
   return fetch(api_url?.stream_api+"/"+encodeURIComponent(streamData?.name?.value),{
      method:"PUT",
      headers: new Headers({
        "Authorization": `Basic ${btoa(`${userData?.login}:${userData?.password}`)}`,
        "Content-Type":"text/json"
      }),
      body:JSON.stringify(
          {
             title:streamData?.title?.value,
             meta:{comments:streamData?.description?.value}
          }        
          )
    }).then((response:any) => {
       return response.json();
    }).then((data:any) => {
      return {status:200,data};
   }).catch((error:any)=>{
      return {message:"Unable to find the data",severity:"error"};
   });
 }

 export const saveInputDetails=(inputDetails:any,userData:any,streamName:string)=>{
   console.log(inputDetails);
   return fetch(api_url?.stream_api+"/"+encodeURIComponent(streamName),{
      method:"PUT",
      headers: new Headers({
        "Authorization": `Basic ${btoa(`${userData?.login}:${userData?.password}`)}`,
        "Content-Type":"text/json"
      }),
      body:JSON?.stringify(inputDetails)
    }).then((response:any) => {
       return response.json();
    }).then((data:any) => {
      return {status:200,data};
   }).catch((error:any)=>{
      return {message:"Unable to find the data",severity:"error"};
   });
 }