import React, { useEffect, useState } from 'react';
import copy from "copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import crossBtn from "../images/cross-btn.svg";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { master_url, outputURL } from '../constants';
import "../css/play.css";
import copyImg from "../images/copy-btn.svg";
import { useDispatch } from 'react-redux';
import { setAlertProps } from '../redux/actions/streams-action';
import { useLocation } from 'react-router';


interface DeleteDialogProps{
    open:boolean,
    handleOk:()=>void,
    handleClose:()=>void,
    selectedRow:any
}

const PlayInfoModal:React.FC<DeleteDialogProps>=({open,handleOk,handleClose,selectedRow})=> {
    const [btnValue,getBtnValue]:any=useState("playbackUrl");
    const [copyText,setCopyText]:any=useState("");
    const dispatch:any=useDispatch();
    const location:any=window.location?.origin;
    console.log(location);

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        btnName: string | null,
      ) => {
        getBtnValue(btnName);
    };

    useEffect(()=>{
        if(copyText){
            copy(copyText);
            dispatch(setAlertProps({message:"Text Copied Successfully !!!",severity:"success"}));
        }
    },[copyText]);
        
    const handleCopyText = (url:any) => {
        setCopyText(url);
    };
 
    const copyToClipboard = () => {
        copy(copyText);
        alert(`You have copied "${copyText}"`);
    };
 
  return (
    <React.Fragment>
      <Modal show={open} onHide={handleClose} id="play-video">
        <Modal.Header className='removeBorder'>
        <ToggleButtonGroup
            value={btnValue}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            >
            <ToggleButton value="playbackUrl" aria-label="playbackUrl">
                <span>Playback URLs</span>
            </ToggleButton>
            <ToggleButton value="play" aria-label="play">
                <span>Play</span>
            </ToggleButton>
          </ToggleButtonGroup>
          <div className='notifications' onClick={handleClose}><img src={crossBtn} height={"15px"}/></div>
        </Modal.Header>
        <Modal.Body>
          <div>
            {btnValue==="playbackUrl" &&outputURL?.map((row:any)=>{
              let url=row.url?.replace("streamname",selectedRow?.name)
             return <div className='url-container'>
                <div className='url-name'>{row.name}</div>
                <div className='url-string'>
                    <span>{url}</span>
          <span onClick={()=>handleCopyText(url)}><img src={copyImg} height={"20px"} width={"20px"}/></span>
                </div>
             </div>})}

             {btnValue==="play" && <iframe style={{width:"100%",height:"310px"}} src={`${master_url}/${selectedRow?.name}/embed.html`}></iframe>}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default PlayInfoModal; 