import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {Alert} from "react-bootstrap";
import InputField from '../components/InputField'
import teamImage from "../images/Team-image.svg";
import "../css/accountSettings.css";
import { getLicenseDetails, getUserDetails, updatePassword } from '../redux/actions/api';
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../redux/actions/action-types';
import { useLocation, useNavigate} from "react-router-dom"
import { loadData, login, setAlertProps, setLicenseDetails, setUserDetails, showError } from '../redux/actions/streams-action';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import hidePasswordIcon from "../images/eye_icon.svg";
import showPasswordIcon from  "../images/eye.svg";
import { userInitialstate } from '../constants';
import { FormControl, InputAdornment, TextField } from '@mui/material';

const AccountSettings=()=>{
  const userDetails:any=useSelector((state:any)=>state?.user);
  const [userCredentials,setUserCredentials]:any=useState({...userInitialstate,login:{...userInitialstate["login"],value:userDetails?.login},password:{...userInitialstate["password"],value:userDetails?.password}});
  const [loading,setLoading]=useState(false);
  const [showpassword,setShowPassword]=useState(false);
  const [showNewPassword,setShowNewPassword]=useState(false);
  const [showNewPassField,setShowNewPassField]=useState(false);
  const dispatch:any=useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const error:any=useSelector((state:any)=>state?.error);
  const licenseDetails:any=useSelector((state:any)=>state?.licenseDetails);
  const statData = useSelector((state:any) => state.overviewData);

  const onChangeValue=(e:any)=>{
     let userdetails:any={...userCredentials,[e.target.name]:{...userCredentials[e.target.name],value:e.target.value}};
     if(["password","newPassword"]?.includes(e.target.name)){
        // if([";"," "]?.includes(e.target.value)){
        //     userdetails={...userdetails,[e.target.name]:{...userdetails[e.target.name],error:{message:"Password isn't valid. It should contain 7 symbols or more. Space symbol and ';' are not acceptable.",iserror:true}}};
        // }else 
        if(userdetails[e.target.name]?.value?.length < 7 || userdetails[e.target.name]?.value?.includes(" ")||userdetails[e.target.name]?.value?.includes(`;`)){
            userdetails={...userdetails,[e.target.name]:{...userdetails[e.target.name],error:{message:"Password isn't valid. It should contain 7 symbols or more. Space symbol and ';' are not acceptable.",iserror:true}}};
        }else if(userdetails["password"]?.value !== userdetails["newPassword"]?.value){
            userdetails={...userdetails,newPassword:{...userdetails["newPassword"],error:{message:"Password doesn't match",iserror:true}}};
          }else if(userdetails["password"]?.value === userdetails["newPassword"]?.value){
              userdetails={...userdetails,newPassword:{...userdetails["newPassword"],error:{}}};
          }else{
            userdetails={...userdetails,[e.target.name]:{...userdetails[e.target.name],error:{}}};
        }
    }
     setUserCredentials(userdetails);
  }

  const onSavePassword=()=>{
    let userdetails:any={...userCredentials};
    let errorKeys:any=[];
    dispatch(loadData(true));
    if(userdetails["login"]?.error?.message||userdetails["password"]?.error?.message ||userdetails["newPassword"]?.error?.message){
      dispatch(loadData(false));
      dispatch(setAlertProps({message:"Failed to update the password",severity:"error"}));
      return;
    }else{
        if (userdetails["login"]?.value !== userDetails?.login){
            userdetails={...userdetails,login:{...userdetails["login"],error:{}}};
        } 
        errorKeys=Object.keys(userdetails)?.filter((key:any)=> userdetails[key]?.error?.iserror)
        setUserCredentials(userdetails);
      }
      errorKeys?.length>0 ? dispatch(loadData(false)): updatePassword(userDetails,userdetails).then((res:any)=>{
      dispatch(loadData(false));
       if(res?.status ==200){
         dispatch(setAlertProps({message:"Password Updated Successfully!!!",severity:"success"}));
         dispatch(setUserDetails(res?.data?.edit_auth));
         setUserCredentials(userInitialstate);
       }else{
        dispatch(setAlertProps({message:"Failed to update the password",severity:"error"}));
       }
    });
   }

  const showPassword=(key:string)=>{
    key==="password"&&setShowPassword(!showpassword);
    key==="newPassword" && setShowNewPassword(!showNewPassword);
  }

  return (<div style={{padding:"10px 0 20px 30px"}}>
    <Breadcrumb id="page-breadcrumb">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="#" active>
        Account Settings
      </Breadcrumb.Item>
    </Breadcrumb>
    <div className="wrapper">
    <div className='login-form' style={{paddingLeft:"0"}}>
        <div className='unlock-text'>Instance</div>
        <div className='subText'>details</div> 
        <div className='account-details'>
            <div className='license-rows'><span className='col-lg-6'>Instance Id</span><span className='col-lg-6'>{licenseDetails?.instance_id}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Billing Plan</span><span className='col-lg-6'>{licenseDetails?.billing_plan}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Firmware</span><span className='col-lg-6'>{licenseDetails?.firmware_version}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Build</span><span className='col-lg-6'>{licenseDetails?.build_version}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Subscription</span><span className='col-lg-6'>{licenseDetails?.subscription_status}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Support</span><span className='col-lg-6'>{licenseDetails?.support_plan}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Support Valid till</span><span className='col-lg-6'>{licenseDetails?.support_valid_till}</span></div>
            <div className='license-rows'><span className='col-lg-6'>Stream license</span><span className='col-lg-6'><span style={{color:"#00d09c"}}>{statData?.total_streams}</span>/100</span></div>
        </div>
      </div>
        <div className="access-container">
        <div className='unlock-text'>Access</div>
        <div className='subText'>Credentials</div> 
        {/* <div style={{marginTop:"35px"}} className='flex-display'>
          <InputField 
          type={"text"}
          name={"login"}
          label={'Username'} 
          value={userCredentials?.login?.value}
          isFloating={true}
          placeholder={''} 
          disabled={true}
          error={userCredentials?.login?.error} 
          onChange={onChangeValue}/></div> */}
          <div className="col-lg-11"  style={{marginTop:"35px"}}>
            <FormControl variant="standard" style={{width:"100%"}}>
              <TextField 
                id="name" 
                name={"login"}
                label={'Username'} 
                value={userCredentials?.login?.value}
                error={userCredentials?.login?.error?.iserror} 
                helperText={userCredentials?.login?.error?.message}
                onChange={(e:any)=>onChangeValue(e)}       
                variant="standard"
              />
            </FormControl>
          </div>
          {/* <div style={{marginTop:"20px"}}>
            <InputField 
          type={showpassword?"text":"password"}
          name={"password"}
          label={'Password'} 
          value={userCredentials?.password?.value}
          isFloating={true}
          placeholder={''} 
          onChange={onChangeValue}
          error={userCredentials?.password?.error}
          imgUrl={showpassword ?showPasswordIcon:hidePasswordIcon}
          imgClick={showPassword}/>
          </div> */}
          <div className="col-lg-11" style={{marginTop:"20px"}}>
            <FormControl variant="standard" style={{width:"100%"}}>
              <TextField 
                id="name" 
                type={showpassword?"text":"password"}
                name={"password"}
                label={'Password'} 
                value={userCredentials?.password?.value}
                placeholder={''} 
                onChange={(e:any)=>onChangeValue(e)}
                error={userCredentials?.password?.error?.iserror}
                helperText={userCredentials?.password?.error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img height="15px" width="15px" style={{cursor:"pointer"}} src={showpassword ? showPasswordIcon : hidePasswordIcon} onClick={() => showPassword("password")} />
                    </InputAdornment>
                  ),
                }}  
                onFocusCapture={()=>setShowNewPassField(true)} 
                variant="standard"
              />
            </FormControl>
          </div>
          {showNewPassField&&<div className="col-lg-11" style={{marginTop:"20px"}}>
            <FormControl variant="standard" style={{width:"100%"}}>
              <TextField 
                id="name" 
                type={showNewPassword?"text":"password"}
                name={"newPassword"}
                label={'Repeat New Password'} 
                value={userCredentials?.newPassword?.value}
                placeholder={''} 
                onChange={(e:any)=>onChangeValue(e)}
                error={userCredentials?.newPassword?.error?.iserror}
                helperText={userCredentials?.newPassword?.error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img height="15px" width="15px" style={{cursor:"pointer"}} src={showNewPassword ?showPasswordIcon:hidePasswordIcon} onClick={() => showPassword("newPassword")} />
                    </InputAdornment>
                  ),
                }}   
                variant="standard"
              />
            </FormControl>
          </div>}
          {/* <div style={{marginTop:"20px"}} className='flex-display'><InputField 
          type={showNewPassword?"text":"password"}
          name={"newPassword"}
          label={'Repeat New Password'} 
          value={userCredentials?.newPassword?.value}
          isFloating={true}
          placeholder={''} 
          onChange={onChangeValue}
          error={userCredentials?.newPassword?.error}
          imgUrl={showNewPassword?showPasswordIcon:hidePasswordIcon}
          imgClick={showPassword}/></div> */}
          <div className='flex-display' style={{margin:"50px 0"}}>
             <button className='button-style' disabled={!userCredentials?.login?.value || !userCredentials?.password?.value || !userCredentials?.newPassword?.value} onClick={()=>onSavePassword()}>Save</button>
             {error && <div className='alert-message'>{error}</div>}
          </div>
        </div>
    </div>
    </div>
  )
}

export default AccountSettings;