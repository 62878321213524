import React, { FC, useEffect, useRef, useState } from "react";
import "../css/multiSelect.css";
import filterIcon from "../images/filter-icon.svg";
import 'bootstrap/dist/css/bootstrap.css';
import profileimg from "../images/profile-image.svg";
import { accountOptions } from "../constants";
import {useLocation, useNavigate} from "react-router-dom";
import { useOutsideClick } from "../interfaces/customHook";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { showError } from "../redux/actions/streams-action";


const ProfileMenu:any = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    let navigation:any=useNavigate();
    let dispatch:any=useDispatch();
   
    const toggleOption=(data:any)=>{
        switch(data.id){
            case "acc_settings":
                navigation("/dashboard/account-settings");
                handleClose();
                return;
            
            case "logout":{
                dispatch(showError(""));
                navigation("/");
                handleClose();
                return;
            }
        }
    }

    return (<React.Fragment>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{padding:0,minWidth:0}}
                >
                    <div className='notifications'>
                    <img src={profileimg} className='profile-image'/>
                    </div>
                </Button>
                <Menu
                    id="account-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {accountOptions.map((option:any)=> {
                        return (<MenuItem onClick={()=>toggleOption(option)}>
                                <span style={{width:"150px",fontSize:"14px"}} className="font-family">{option.title}</span>
                                <span><img src={option.img}/></span>
                                </MenuItem>)
                    })}
                </Menu>
            </React.Fragment>
    )
}

export default ProfileMenu;