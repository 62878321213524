import React, { ChangeEvent, FC, useState } from 'react'
import "../css/inputField.css"
import { Button, ClickAwayListener, FormControl, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import infoicon from "../images/Info-icon.svg";


interface InputFieldProps {
  type: 'text' | 'number' | 'email' | 'password' |'select'
  label: string
  value?: any 
  isFloating: boolean
  name?: string
  placeholder: string
  error?: any
  disabled?: boolean
  required?:boolean
  options?:any[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  showInfo?: boolean,
  tooltipDesc?:any,
  imgUrl?:any,
  imgClick?:any,
  width?:any
}

const InputField: FC<InputFieldProps> = ({
  type,
  label,
  isFloating,
  name,
  value,
  placeholder,
  error,
  disabled,
  required,
  options,
  onChange,
  showInfo,
  tooltipDesc,
  imgUrl,imgClick,width
}) => {
   const [showInfoIcon,setShowInfoIcon]=useState(showInfo || false);
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (<div className='input-wrapper'>
    {type!=="select"?
    <div className="input-container">
      
      {imgUrl && <img src={imgUrl} onClick={() => imgClick && imgClick(name)} />}
      {showInfoIcon && <>
          <img src={infoicon} onClick={(e:any)=>handleClick(e)}/>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
            <Typography sx={{ p: 2 }}>{tooltipDesc}</Typography>
          </Popover></>}
      <input className={error?.message?"errorInput":""} disabled={disabled} type={type} value={value} name={name} onChange={(e:any)=>onChange(e)} />
      <label className={value && 'filled'} htmlFor={name}>
        {label}
      </label>
    </div>
    :
//     <div className="form-group inputField">
//     <select className="form-control" name={name} id="selectCtrl" value={value} onChange={(e:any)=>onChange(e)}>
//       <option value="auto">Auto</option>
//     </select>
//     <label className={`control-label ${value && "filled"}`} htmlFor="selectCtrl">Select Option Value</label>
// </div>
<FormControl variant="standard" style={{width:width||"100%",textAlign:"left"}}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          defaultValue={""}
          id={name}
          value={value}
          onChange={(e:any)=>onChange(e)}
          label={label}
          name={name}
          error={error||false}
          style={{fontFamily:"LufgaRegular",fontSize:"14px"}}
        >
          {options?.map((item:any)=>{
              return <MenuItem value={item?.value} style={{fontFamily:"LufgaRegular",fontSize:"14px"}}>{item?.text}</MenuItem>
          })}
        </Select>
      </FormControl>
}
{error?.message && <div className='errorMessage'>{error?.message}</div>}
</div>
  );
}

export default InputField;