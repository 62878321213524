import {ActionTypes} from './action-types'

export const login = (data:any) => ({
    type: ActionTypes.LOGIN_DATA,
    data
})

export const setStreamData = (data:any)=>({
  type:ActionTypes.SET_STREAMS_DATA,
  data
})

export const loadData = (data:boolean)=>({
  type: ActionTypes.LOAD_DATA,
  data
})

export const setLicenseDetails = (data:any)=>({
  type: ActionTypes.SET_LICENSE_DATA,
  data:{data}
})

export const setSelectedData= (data:any)=>({
  type: ActionTypes.SET_SELECTED_ROWS,
  data
})

export const showError=(error:string)=>({
  type:ActionTypes.SHOW_ERROR,
  error
})

export const setAlertProps=(alertProps:any)=>({
  type:ActionTypes.SET_ALERT_PROPS,
  data:alertProps
})

export const setShowAlert=(showAlert:boolean)=>
({
  type:ActionTypes.SET_SHOW_ALERT,
  data:showAlert
})

export const setUserDetails=(userCreds:any)=>({
  type:ActionTypes.SET_USER_DETAILS,
  data:userCreds
})

export const setOverviewData=(data:any)=>({
  type:ActionTypes.SET_OVERVIEW_DATA,
  data
})


export const setInitialState=()=>({
  type:ActionTypes.SET_INITIAL_STATE
})

export const setSearchString=(searchString:string)=>
({
  type:ActionTypes.SET_SEARCH_STRING,
  data:searchString
})

export const setStatusFilters=(statusFilter:string)=>
({
  type:ActionTypes.SET_STATUS_FILTER,
  data:statusFilter
})

export const rowToEdit=(rowData:any)=>({
  type:ActionTypes.ROW_TO_EDIT,
  data:rowData
})

export const editOverviewDetails=(rowData:any)=>({
  type:ActionTypes.UPDATE_OVERVIEW_DATA,
  data:rowData
})

export const setVideoList=(list:any,isInitial:boolean)=>({
  type:ActionTypes.SET_VIDEO_LIST,
  data:{tracks:list,isInitial}
})

export const setLogoList=(logoList:any)=>({
  type:ActionTypes.SET_LOGO_LIST,
  data:logoList
})

export const setSelectedlogo=(logo:any)=>({
  type:ActionTypes.SET_SELECTED_LOGO,
  data:logo
})