import React,{FC} from "react";
import "../css/card.css";
import { Circle } from "rc-progress";

type CardProps = {
    title:string,
    text?:any,
    type?:string,
    onClick?: () => void,
    children?:any
    percent?:number
    titlePlacement?:string
    };

const Card:FC<CardProps> =({title,text,type,onClick,children,percent,titlePlacement})=> {
 switch(type){
    case "count":
        return <div className="card-container">
                  <div className="title">{title}</div>
                  <div className="count">{text}</div>
                </div>;
    case "progress":
        return <div className="card-container">
                  <div className="circle-progress-continer" style={{flexDirection:titlePlacement==="right"?"row":"row-reverse"}}>
                    <div className="donut-progress">
                    <div className="progress-percent">{percent+"%"}</div>
                    <div><Circle percent={percent} strokeWidth={5}  trailColor={"#e6fbf6"} trailWidth={5} strokeColor="#00D09C" /></div>
                    </div>
                    <div className="title">{title}</div>
                    </div>
                </div>;

    case "line-cards":
        return <div className='acc-cards'>
            <div className="card-title">{title}</div>
            <div>{children}</div>
        </div>

    default:
        return <div className="card-container">
        <div className="title">{title}</div>
        <div className="count">{text}</div>
      </div>;
 }
 
};
export default Card;