import gear_icon from "./images/gearIcon.svg";
import logout from "./images/logout_icon.svg";



export const overviewImg:any=<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.7 14.0368C6.68234 17.0191 11.5177 17.0191 14.5 14.0368C17.4823 11.0544 17.4823 6.21909 14.5 3.23675C11.5177 0.254415 6.68234 0.254416 3.7 3.23675C0.717663 6.21909 0.717663 11.0544 3.7 14.0368ZM3.7 14.0368L1 16.7368M6.0895 10.8263V8.82632M9.0895 10.8263V6.82632M12.0895 10.8263V8.82632" stroke="#44475B" stroke-linecap="round"/>
</svg>;

export const livestream:any=<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1422 4H3.44118C2.09295 4 1 5.23412 1 6.75649V12.2435C1 13.7659 2.09295 15 3.44118 15H14.1422C15.4904 15 16.5833 13.7659 16.5833 12.2435V6.75649C16.5833 5.23412 15.4904 4 14.1422 4Z" stroke="#44475B" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M5 1L8.1253 3.50024C8.49052 3.79242 9.00948 3.79242 9.3747 3.50024L12.5 1" stroke="#44475B" stroke-linecap="round"/>
</svg>;

export const searchIcon:any=<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3274 11.4425C13.7809 8.98896 13.7809 5.01104 11.3274 2.55753C8.87391 0.104014 4.89599 0.104014 2.44247 2.55753C-0.0110363 5.01104 -0.0110361 8.98896 2.44248 11.4425C4.89599 13.896 8.87391 13.896 11.3274 11.4425ZM11.3274 11.4425L14.4633 14.5783" stroke="#44475B" stroke-linecap="round"/>
</svg>;

export const play_icon:any=<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M377.971959 333.319481 690.656471 511.932462 377.971959 690.678472 377.971959 333.319481zM958.70846 243.88252l0 536.102954c0 98.730629-79.971379 178.744987-178.615027 178.744987L243.903497 958.730461c-98.642624 0-178.61298-80.013335-178.61298-178.744987L65.290517 243.88252c0-98.644671 79.969333-178.615027 178.61298-178.615027l536.190959 0C878.73708 65.267493 958.70846 145.236825 958.70846 243.88252zM869.401458 288.643979c0-74.080206-59.989279-134.069485-134.069485-134.069485L288.667004 154.574494c-74.082252 0-134.115534 59.989279-134.115534 134.069485L154.55147 735.309972c0 74.03825 60.033281 134.113487 134.115534 134.113487l446.664969 0c74.080206 0 134.069485-60.075237 134.069485-134.113487L869.401458 288.643979z"  /></svg>;


export const doubleArrowsLeft:any=<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M872.533 819.627a34.133 34.133 0 0 1-48.213 48.213L492.373 535.893a34.133 34.133 0 0 1 0-48.213L824.32 155.733a34.133 34.133 0 0 1 48.213 48.214L564.907 512z m-341.333 0a34.133 34.133 0 0 1-48.213 48.213l-331.52-331.52a34.133 34.133 0 0 1 0-48.213L483.413 156.16a34.133 34.133 0 0 1 48.214 48.213L223.573 512z"  /></svg>;

export const doubleArrowsRight:any=<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M151.467 204.373a34.133 34.133 0 1 1 48.213-48.213l331.947 331.947a34.133 34.133 0 0 1 0 48.213L199.68 867.84a34.133 34.133 0 1 1-48.213-48.213L459.093 512z m341.333 0a34.133 34.133 0 0 1 48.213-48.213L872.96 488.107a34.133 34.133 0 0 1 0 48.213L541.013 868.267a34.133 34.133 0 0 1-48.213-48.214L800.427 512z"  /></svg>

export const navigationItems:any=[
    {
      name: 'Overview ',
      url: '/dashboard/overview', 
      svgImg: overviewImg,
      id:"overviewlbl"
    },
    {
      name: 'Live Streams',
      url: '/dashboard/live-streams', 
      svgImg:livestream,
      id:"livestreamslbl"
    }
  ];

const base_url=window?.location?.origin;

export const outputURL:any=[{name:"HLS",url:base_url+"/streamname/playlist.m3u8"},
                            {name:"Dash",url:base_url+"/streamname/index.mpd"},
                            {name:"RTMP",url:`rtmp://${window?.location?.host}/static/streamname`}];

  export const menuOptions = [
    { id: "running", title: 'Online' },
    { id: "waiting", title: 'Waiting' },
    { id: "error", title: 'Error' },
    { id: "disabled", title: 'Disabled' },
    { id: "transcoded", title: 'Transcoded' }
  ];

  export const videoTracks = [
    {id:"3500k . 1080p",title:"3500k . 1080p"},
    {id:"2500k . 720p",title:"2500k . 720p"},
    {id:"1500k . 576p",title:"1500k . 576p"},
    {id:" 1000k . 480p",title:" 1000k . 480p"},
    {id:"700k . 360p",title:"700k . 360p"}
  ];

  export const deviceOptions=[
    {text:"CPU Encoder",value:"CPU Encoder"},
    {text:"Tesla K10.G1.8GB",value:"Tesla K10.G1.8GB"},
    {text:"Tesla K10.G2.8GB",value:"Tesla K10.G2.8GB"}
  ];

  export const fpsOptions=[
    {text:"None",value:""},
    {text:"24",value:"24"},
    {text:"25",value:"25"},
    {text:"30",value:"30"},
    {text:"50",value:"50"},
    {text:"60",value:"60"}
  ];

  export const channelOptions=[
    {text:"None",value:""},
    {text:"1",value:"1"},
    {text:"2",value:"2"},
    {text:"4",value:"4"},
    {text:"5",value:"5"},
    {text:"6",value:"6"},
    {text:"8",value:"8"}
  ];

  export const heightOptions=[
    {text:"None",value:""},
    {text:"240",value:"240"},
    {text:"360",value:"360"},
    {text:"576",value:"576"},
    {text:"720",value:"720"},
    {text:"1080",value:"1080"}
  ];

  export const audioCodecOptions=[
    {text:"None",value:""},
    {text:"aac",value:"aac"},
    {text:"opus",value:"opus"},
    {text:"mp2a",value:"mp2a"},
    {text:"ac3",value:"ac3"},
    {text:"pcma",value:"pcma"}
  ];

  export const videoCodecOptions=[
    {text:"None",value:""},
    {text:"h264",value:"h264"},
    {text:"hev",value:"hev"},
    {text:"av1",value:"av1"},
    {text:"mp2v",value:"mp2v"}
  ];

  export const resizeOptions=[
    {text:"None",value:""},
    {text:"Crop",value:"crop"},
    {text:"Scale",value:"scale"},
    {text:"Fit",value:"fit"}
  ];

  export const profileOptions=[
    {text:"None",value:""},
    {text:"Baseline",value:"baseline"},
    {text:"High",value:"high"},
    {text:"Main",value:"main"}
  ];

  export const interlaceOptions=[
    {text:"None",value:""},
    {text:"Progressive",value:"false"},
    {text:"Interlaced",value:"true"},
    {text:"Top Field first",value:"tff"},
    {text:"Bottom field first",value:"bff"}
  ];

  export const presetOptions=[
    {text:"None",value:""},
    {text:"Slow",value:"slow"},
    {text:"Medium",value:"medium"},
    {text:"Fast",value:"fast"},
    {text:"Very Fast",value:"veryfast"},
    {text:"Ultra Fast",value:"ultrafast"}
  ];

  export const refsOptions=[
    {text:"None",value:""},
    {text:"1",value:"1"},
    {text:"2",value:"2"},
    {text:"3",value:"3"},
    {text:"4",value:"4"},
    {text:"5",value:"5"},
    {text:"6",value:"6"}
  ];

  export const levelOptions=[
    {text:"None",value:""},
    {text:"3.1",value:"3.1"},
    {text:"3.2",value:"3.2"},
    {text:"4",value:"4"},
    {text:"4.1",value:"4.1"},
    {text:"4.2",value:"4.2"},
    {text:"5",value:"5"},
    {text:"5.1",value:"5.1"},
    {text:"5.2",value:"5.2"},
    {text:"6",value:"6"},
    {text:"6.1",value:"6.1"},
    {text:"6.2",value:"6.2"}
  ];

  export const positionOptions=[
    {text:"None",value:""},
    {text:"Top Left Corner",value:"tl"},
    {text:"Top Right Corner",value:"tr"},
    {text:"Center",value:"center"},
    {text:"Bottom Left Corner",value:"bl"},
    {text:"Bottom Right Corner",value:"br"}
  ];

  export const bFramesOptions=[
    {text:"None",value:""},
    {text:"IP",value:"0"},
    {text:"IBP",value:"1"},
    {text:"IBBP",value:"2"},
    {text:"IBBBP",value:"3"},
    {text:"IBBBBP",value:"4"}
  ];

  export const sampleRateOptions=[
    {text:"bypass",value:""},
    {text:"8000",value:"8000"},
    {text:"16000",value:"16000"},
    {text:"24000",value:"24000"},
    {text:"32000",value:"32000"},
    {text:"44100",value:"44100"},
    {text:"48000",value:"48000"},
  ];

  export const deinterlaceOptions=[
    {text:"Off",value:"off"},
    {text:"On",value:"on"},
    {text:"On Double Rate",value:"on double rate"},
    {text:"Adaptive",value:"adaptive"},
    {text:"Adaptive Double rate",value:"adaptive double rate"},
    {text:"Yadif",value:"yadif"}
  ];

  export const accountOptions = [
    { id: "acc_settings", title: 'Account Settings', img:gear_icon },
    { id: "logout", title: 'Logout',img:logout }
  ];

  export const master_url:any="https://node.edg.live";
  export const api_url={
    user_details_api:master_url+"/streamer/api/v3/config",
    stream_api:master_url+"/streamer/api/v3/streams",
    license_api:"https://api-edgcoder.edg.live:8443/coder/user/mk/api.json",
    base_action_api:master_url+"/streamer/api/v3/streams",
    get_logos_api:master_url+"/streamer/api/v3/logos",
  }

  export const userInitialstate:any={login:{value:'',error:{}},password:{value:'',error:{}},newPassword:{value:"",error:{}}};

  export const streamInitialstate:any={name:{value:'',error:{}},title:{value:'',error:{}},description:{value:"",error:{}}};

  export const videoSettingsInitialState:any={
    device:{value:'',error:false},
    deinterlace:{value:'',error:false},
    cpuDecoding:{value:"",error:false},
    gopSize:{value:"",error:false},
    fps:{value:"",error:false}
  };

  export const audioSettingsInitialState:any={
    bitrate:{value:"",error:false},
    codec:{value:"",error:false},
    sample_rate:{value:"",error:false},
    channel:{value:"",error:false}
  }

  export const initialPushValue:any={
    disabled:false,
    url:{value:"",error:{}},
    uptime:{value:"",error:{}},
    status:{value:"",error:{}}
}