import { useEffect, useState } from "react";
import "../css/transcodedSettings.css";
import ToggleButton from 'react-bootstrap/ToggleButton'
import { Button, FormControl, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import InputField from "./InputField";
import { audioCodecOptions, audioSettingsInitialState, channelOptions, deinterlaceOptions, deviceOptions, fpsOptions, sampleRateOptions, videoSettingsInitialState, videoTracks } from "../constants";
import { formatTrackList, getAudioSettings, getDecoderSettings, getDeviceOptions, getFormattedValue, getInitialValue, getVideoSettings, hasValidationError, hex2rgb } from "../common-utils";
import VideoTrack from "./VideoTrack";
import { useDispatch, useSelector } from "react-redux";
import { loadData, rowToEdit, setAlertProps, setVideoList } from "../redux/actions/streams-action";
import { saveInputDetails } from "../redux/actions/api";

export default function TranscodedSettings() {
    const rowData:any=useSelector((state:any)=>state?.rowToEdit);
    const statData = useSelector((state:any) => state.overviewData);
    const userDetails:any=useSelector((state:any)=>state?.user);
    const [audioCopyInput,setAudioCopyInput]=useState(false);
    const [videoCopyInput,setVideoCopyInput]=useState(false);
    const [audioCopyInputData,setAudioCopyInputData]:any=useState({bitrate:{value:"copy"},codec:{value:null},sample_rate:{value:null}});
    const [videoCopyInputData,setVideoCopyInputData]:any=useState({track:{value:1},bitrate:{value:"copy"}});
    const [audioSettings,setAudioSettings]:any=useState(getAudioSettings(rowData?.transcoder?.audio));
    const [videoSettings,setVideoSettings]:any=useState(getVideoSettings(statData?.transcoder_devices,rowData));    
    const [decoderSetting,setDecoderSettings]:any=useState(getDecoderSettings(rowData?.transcoder?.decoder));    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    useEffect(()=>{
      Object?.keys(rowData)?.length > 0 && rowData?.transcoder?.video?.length >0 && dispatch(setVideoList(rowData?.transcoder?.video,true));
    },[])

    // useEffect(()=>{
    //     audioCopyInput && setAudioSettings();
    //     !audioCopyInput && setAudioSettings(getAudioSettings(rowData?.transcoder?.audio));
    // },[audioCopyInput])

    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const videoTracksList:any=useSelector((state:any)=>state?.videoTracklist);
    const [videoTrack,setVideoTrack]=useState({});
    const deviceOptions:any=getDeviceOptions(statData?.transcoder_devices);
    let formattedAudioSettings:any={};
    let formattedVideoSettings:any={};
    let formattedDecoderSetting:any={};
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSaveStreamData=(e:any)=>{
        e.preventDefault();
        if(hasValidationError(audioSettings) || hasValidationError(videoSettings)){
            dispatch(loadData(false));
            dispatch(setAlertProps({message:"Please resolve the errors and then proceed to save",severity:"error"}));
        }else {
            if(!audioCopyInput){
                Object.keys(audioSettings)?.forEach((setting:any)=>{
                    if(setting==="bitrate"){formattedAudioSettings[setting]=audioSettings[setting]?.value*1000;
                    }else{ formattedAudioSettings[setting]=audioSettings[setting]?.value;}
                 });
           }else if(audioCopyInput){
              Object.keys(audioCopyInputData)?.forEach((setting:any)=>{
                formattedAudioSettings[setting]=audioCopyInputData[setting]?.value;
             });
           } else if(!videoCopyInput){
             Object.keys(decoderSetting)?.forEach((setting:any)=>{
                if(setting==="cpuDecoding"){
                    const cropValue = decoderSetting?.cpuDecoding?.value?.split(":");
                    const { left, top, width, height }:any = cropValue?.length === 4 ? { left: cropValue[0], top: cropValue[1], width: cropValue[2], height: cropValue[3] } : {};
                    formattedDecoderSetting = { crop: { left, top, width, height } };
                    delete formattedDecoderSetting?.cpuDecoding;
                }
             });
             Object.keys(videoSettings)?.forEach((setting:any)=>{
                formattedVideoSettings[setting]=videoSettings[setting]?.value;
             });
           } else if(videoCopyInput){
             Object.keys(videoCopyInputData)?.forEach((setting:any)=>{
                formattedVideoSettings[setting]=videoCopyInputData[setting]?.value;
             });
           }
        
           let globalObj:any={};
           Object.keys(videoSettings)?.forEach((settingKey:any)=>{
               if(videoSettings[settingKey]?.value!==''){
                if(settingKey==="device"){
                    const [text, number] = videoSettings?.device?.value?.match(/([^\(]+)\((\d+)\)/)?.slice(1) || [];
                    let deviceArray:any=text ? [text.trim(), parseInt(number, 10)] : videoSettings?.device?.value;
                    globalObj["deviceid"]=deviceArray[1];
                    globalObj["hw"]=deviceArray[0];
                }else{
                    globalObj[settingKey]=globalObj[settingKey]?.value;
                }
               }
           });
            const saveData:any={
                transcoder:{
                    audio:formattedAudioSettings,
                    decoder:formattedDecoderSetting,
                    video:videoTracksList?.map((vid:any)=>{
                        let obj:any={};
                        Object.keys(vid)?.forEach((v:any)=>{
                            if(v==="logo" || v==="position"){
                                obj["logo"]={path:vid["logo"]?.value,position:vid["position"]?.value}
                            }else if(v==="sar"){
                                const sarValue:any=vid?.sar?vid?.sar?.value?.split(":"):[];
                                obj["sar"]={x:sarValue[0]||"",y:sarValue[1]};
                            }else if(v==="height"||v==="weight"||v==="resize" || v==="background"){
                                obj["size"]={width:vid["width"]?.value,height:vid["height"]?.value,strategy:vid["resize"]?.value,background:vid["backround"]?.value}

                            }else if(vid[v]?.value!=='' && v!=="track"){
                                obj[v]=vid[v]?.value;
                            }
                        })
                        return obj;
                    }),
                    global:globalObj
                }};
           
           saveInputDetails(saveData,userDetails,rowData?.name)?.then((data:any)=>{
            dispatch(loadData(false));
            if(data?.error){
                dispatch(setAlertProps({message:data?.error,severity:"error"}));
            } else{
                dispatch(rowToEdit(data?.data));
                dispatch(setAlertProps({message:"Transcoder data updated successfully!!!",severity:"success"}));
            }
           })
        }
    }

    const disableTranscoder=()=>{
        const saveData:any={transcoder:null};
        saveInputDetails(saveData,userDetails,rowData?.name)?.then((data:any)=>{
                dispatch(loadData(false));
                if(data?.error){
                    dispatch(setAlertProps({message:data?.error,severity:"error"}));
                } else{
                    dispatch(rowToEdit(data?.data));
                    dispatch(setAlertProps({message:"Transcoder disabled successfully!!!",severity:"success"}));
                }
               })
    }

    const getVideoTrackValue=(ddValue:any)=>{
        let list:any[]=[...videoTracksList];
        let newTrack:any=getInitialValue({height:ddValue?.split(".")[1]?.split("p")[0]?.trim(),bitrate:ddValue?.split(".")[0]?.split("k")[0]});
        list?.push(newTrack);
        dispatch(setVideoList(list,false));
        setVideoTrack(newTrack);
    }

    const onChangeValue=(e:any,type:string)=>{
         let videoDetails:any;
         let audioDetails:any;
         let decoderDetails:any;
         let outputString:any;
        if(type==="video"){
            videoDetails={...videoSettings,[e.target.name]:{...videoSettings[e.target.name],value:e.target.value}};
            if(e.target.name==="gopSize" &&  (e.target.value < 1 || e.target.value > 1000)){
                videoDetails={...videoSettings,[e.target.name]:{...videoSettings[e.target.name],value:e.target.value,error:{message:"GOP should be between 1-1000",iserror:true}}};
            }else {
                videoDetails={...videoSettings,[e.target.name]:{...videoSettings[e.target.name],value:e.target.value,error:{}}};
            }
            setVideoSettings(videoDetails);
        } 
        if(type==="decoder"){
            decoderDetails={...decoderSetting,[e.target.name]:{...decoderSetting[e.target.name],value:e.target.value}};
            if(e.target.name==="cpuDecoding"){
                outputString=getFormattedValue(decoderDetails?.cpuDecoding?.value,5,23);
                decoderDetails={...decoderSetting,[e.target.name]:{...decoderSetting[e.target.name],value:outputString}};
                if(decoderDetails?.cpuDecoding?.value?.length<23){
                decoderDetails={...decoderDetails,cpuDecoding:{...decoderDetails?.cpuDecoding,error:{message:"Specify all settings or nothing",iserror:true}}};
                }else{
                    decoderDetails={...decoderDetails,cpuDecoding:{...decoderDetails?.cpuDecoding,error:{}}};
                } 
            }
            setDecoderSettings(decoderDetails);
        }
        if(type==="audio"){
            audioDetails={...audioSettings,[e.target.name]:{...audioSettings[e.target.name],value:e.target.value}};
            setAudioSettings(audioDetails);
        }
    }

  const copyInput=(type:string)=>{
    if(type==="audio"){
       setAudioCopyInput(!audioCopyInput);
    }
    if(type==="video"){
        setVideoCopyInput(!videoCopyInput);
    }
  }

  return (<div>
    <div className="audio-settings col-lg-12">
        <div className="card-title">Audio</div>
        <div className="copy-input">
            <div>Copy input</div>
            <div className='transcoder-outer-circle' onClick={()=>copyInput("audio")}>
                <span className={audioCopyInput?'transcoder-inner-circle-right':'transcoder-inner-circle-left'} />
            </div>
        </div>
        {!audioCopyInput && <div className="row flex-display" style={{padding:"15px 5px",alignItems:"center"}}>
        <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
             <TextField 
               id="bitrate" 
               name="bitrate"
               value={audioSettings?.bitrate?.value} 
               error={audioSettings?.bitrate?.error?.iserror}
               helperText={audioSettings?.bitrate?.error?.message} 
               type="number" 
               label="Bitrate" 
               InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{fontFamily:"LufgaRegular"}}>k</span>
                  </InputAdornment>
                ),
              }}      
               variant="standard"
               onChange={(e:any)=>onChangeValue(e,"audio")}/>
          </FormControl></div>
          <div className="col-lg-3"><InputField 
          type={"select"}
          name={"codec"}
          label={'Codec'} 
          value={audioSettings?.codec?.value}
          isFloating={true}
          placeholder={''} 
          error={audioSettings?.codec?.error?.iserror} 
          onChange={(e:any)=>onChangeValue(e,"audio")}
          options={audioCodecOptions}/></div>
          <div className="col-lg-3"><InputField 
          type={"select"}
          name={"sample_rate"}
          label={'Sample rate'} 
          value={audioSettings?.sample_rate?.value}
          isFloating={true}
          placeholder={''} 
          error={audioSettings?.sample_rate?.error?.iserror} 
          onChange={(e:any)=>onChangeValue(e,"audio")}
          options={sampleRateOptions}/></div>
          <div className="col-lg-3"><InputField 
          type={"select"}
          name={"channel"}
          label={'Channel'} 
          value={audioSettings?.channel?.value}
          isFloating={true}
          placeholder={''} 
          error={audioSettings?.channel?.error?.iserror} 
          onChange={(e:any)=>onChangeValue(e,"audio")}
          options={channelOptions}/></div>
        </div>}
    </div>
    <div className="video-settings col-lg-12">
        <div className="video-select-wrapper">
            <div className="card-title">Video</div>
            <div className="video-dd">
                <Dropdown className="d-inline mx-2"  
                   onSelect={(eventKey: any, event: Object) => getVideoTrackValue(eventKey)}>
                    <Dropdown.Toggle id="dropdown-autoclose-true" disabled={videoCopyInput||false}>
                    Add Video track
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {videoTracks?.map((video:any)=>{
                           return <Dropdown.Item eventKey={video?.id}>{video?.title}</Dropdown.Item>;
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
        <div className="copy-input">
            <div>Copy input</div>
            <div className='transcoder-outer-circle' onClick={()=>copyInput("video")}>
                    <span className={videoCopyInput?'transcoder-inner-circle-right':'transcoder-inner-circle-left'} />
            </div>
        </div>
        {!videoCopyInput && <div className="row flex-display" style={{padding:"15px 5px",alignItems:"center",lineHeight:"55px"}}>
         <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField
                  select 
                  id="device"
                  name="device" 
                  label="Device" 
                  variant="standard"
                  style={{textAlign:"left"}}
                  value={videoSettings?.device?.value}
                  error={videoSettings?.device?.error?.iserror}
                  helperText={videoSettings?.device?.error?.message}
                  onChange={(e:any)=>onChangeValue(e,"video")}>
                    {deviceOptions.map((option:any) => (
                        <MenuItem key={option.value} value={option.value} style={{fontSize:"14px"}}>
                        {option.text}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
          </div>
          <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField
                  select 
                  id="deinterlance"
                  name={"deinterlace"}
                  label={'Deinterlace'} 
                  variant="standard"
                  style={{textAlign:"left"}}
                  value={decoderSetting?.deinterlace?.value}
                  error={decoderSetting?.deinterlace?.error?.iserror}
                  helperText={decoderSetting?.deinterlace?.error?.message}
                  onChange={(e:any)=>onChangeValue(e,"decoder")}>
                    {deinterlaceOptions.map((option:any) => (
                        <MenuItem key={option.value} value={option.value} style={{fontSize:"14px"}}>
                        {option.text}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
          </div>
          <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField 
                id="cpuDecoding" 
                name="cpuDecoding"
                label="Crop after decoding" 
                variant="standard"
                placeholder="-- : -- : -- : --"
                value={decoderSetting?.cpuDecoding?.value}
                error={decoderSetting?.cpuDecoding?.error?.iserror}
                helperText={decoderSetting?.cpuDecoding?.error?.message}
                onChange={(e:any)=>onChangeValue(e,"decoder")}/>
            </FormControl>
           </div>
           <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField 
                 id="gopSize" 
                 type="number"
                 name="gopSize"
                 label="GOP size" 
                 variant="standard"
                 value={videoSettings?.gopSize?.value}
                 error={videoSettings?.gopSize?.error?.iserror}
                 helperText={videoSettings?.gopSize?.error?.message}
                 onChange={(e:any)=>onChangeValue(e,"video")}/>
            </FormControl>
            </div>
            <div className="col-lg-3">
            <FormControl variant="standard" style={{width:"100%"}}>
                <TextField
                  select 
                  id="fps"
                  name={"fps"}
                  label={'FPS'} 
                  variant="standard"
                  style={{textAlign:"left"}}
                  value={videoSettings?.fps?.value}
                  error={videoSettings?.fps?.error?.iserror}
                  helperText={videoSettings?.fps?.error?.message}
                  onChange={(e:any)=>onChangeValue(e,"video")}>
                    {fpsOptions.map((option:any) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.text}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>    
            </div>    
        </div>}
        {!videoCopyInput && (Object.keys(videoTrack)?.length > 0 || rowData?.transcoder?.video?.length >0 )&& <div>
            <VideoTrack />
        </div>}
    </div>
    <div className="flex-display" style={{alignItems:"center"}}>
            <button type="button" className='button-style' style={{margin:"80px 10px",background:hex2rgb("#00D09C"),color:"#00D09C"}} onClick={(e:any)=>onSaveStreamData(e)}>
              Save
            </button>
            <button className='button-style' style={{margin:"80px 10px",width:"auto"}} onClick={()=>disableTranscoder()}>Disable Transcoder</button>
          </div>
  </div>)
}