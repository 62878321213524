import { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import crossBtn from "../images/cross-btn.svg";

interface ModalProps {
  customHeaderClass?:string
  size?:any
  show:boolean
  title:string
  footer?:any
  children?:any
  closable?:boolean
  handleClose?:()=>void
  handleShow?:()=> void
}

const ModalContainer: FC<ModalProps> = ({
  show,handleClose,handleShow,footer,children,title,customHeaderClass,closable,size
}) => {  
  return (
    <>
      <Modal show={show} onHide={handleClose} size={size}>
        <Modal.Header  className={customHeaderClass}>
          <Modal.Title>{title}</Modal.Title>
          {closable && <div className='notifications' onClick={handleClose}><img src={crossBtn} height={"15px"}/></div>}
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        {footer&&<Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          {footer}
        </Modal.Footer>}
      </Modal>
    </>
  );
}

export default ModalContainer;