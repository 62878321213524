import { FC, useState } from 'react';
import "../css/dashboard.css";
import ModalContainer from '../components/ModalContainer';
import errorImg from "../images/error_img.svg";

interface ModalProps {
  showModal:boolean,
  onClose:any
}

const ErrorModal: FC<ModalProps> = ({
  showModal,onClose
}) => {  
  return (
    <ModalContainer size={"lg"} closable={true} customHeaderClass="removeBorder" title='' show={showModal} handleClose={()=>onClose("errorModal")}>
            <div className='flex-display'>
              <div className='modalContent'>
                <div className='header-text'>We,re Sorry</div>
                <div className='sub-text'>You've hit your stream limit. Reach out to our support team for extra help.</div>
              </div>
              <div><img src={errorImg} height={"250px"} width={"250px"}/></div>
              </div>
            </ModalContainer>
  );
}

export default ErrorModal;