import React, { useEffect, useState } from 'react'
import "../css/livestream.css";
import DataTable, { TableColumn } from 'react-data-table-component';
import refresh from "../images/refresh.svg";
import play from "../images/play-icon.svg";
import deleteIcon from "../images/delete-btn.svg";
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../redux/actions/action-types';
import { deleteStream, disableEnableStream, getStreamsList, refreshRow } from '../redux/actions/api';
import { generatePath, useLocation, useNavigate, useParams} from "react-router-dom"
import { getTextColor } from '../common-utils';
import { loadData, rowToEdit, setAlertProps, setSelectedData, setShowAlert } from '../redux/actions/streams-action';
import DeleteDialog from './DeleteDialog';
import PlayInfoModal from './PlayInfoDialog';

const LiveStreams: React.FC = () => {
    const [selectedRows,setSelectedRows]=useState([]);
    const [pageLimit,setPageLimit]=useState(100);
    const [showDeleteAlert,setShowDeleteAlert]=useState(false);
    const [rowDelete,setRowDelete]:any=useState({});
    const [playStream,setPlayStream]:any=useState({});
    const [showPlayModal,setShowPlayModal]=useState(false);
    const dispatch:any=useDispatch();
    const navigation:any = useNavigate();
    const location:any = useLocation();
    const userData = useSelector((state:any) => state.user);
    const livestreams:any[]=useSelector((state:any)=>state.liveStreams);
    const rowsSelected:any[]=useSelector((state:any)=>state?.selectedRows)||[];
    const searchString:string=useSelector((state:any)=>state?.searchString);
    const filter:string=useSelector((state:any)=>state?.statusFilter);

    useEffect(()=>{
        let interval = setInterval(() => {
         getStreamsList(userData,100,searchString,filter).then((data:any)=>{
              dispatch({type:ActionTypes.SET_STREAMS_DATA,data});
            })
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    })

    useEffect(()=>{
        dispatch(loadData(true));
        getStreamsList(userData,100,searchString,filter).then((data:any)=>{
          dispatch({type:ActionTypes.SET_STREAMS_DATA,data});
        })
      },[searchString,filter])

    const restartStream=(rowData:any)=>{
        dispatch(loadData(true));
        refreshRow(rowData,userData).then((res:any)=>{
            dispatch(loadData(false));
            if(res?.data?.status === 200){
               dispatch(setAlertProps({message:"The stream has been restarted successfully !!!",severity:"success"}));
            } else {
                dispatch(setAlertProps({message:"Unable to restart as the stream is disabled.Please enable the stream",severity:"error"}));
            }
        });
    }

    const disableStreamRow=(rowData:any)=>{
        loadData(true);
        disableEnableStream(rowData,userData,!rowData?.disabled).then((res:any)=>{
            loadData(false);
            if(res?.data?.status === 200){
               dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
            } else {
                dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
            }
        });
    }

    const onNameClick=(e:any,rowData:any)=>{
        //dispatch(rowToEdit(rowData))
        navigation(generatePath("/dashboard/live-streams/details/:id", {id:encodeURIComponent(rowData?.name)}));
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'Stream',
            cell: row => {
                return <span style={{color:"#00D09C",cursor:"pointer"}} onClick={(e:any)=>onNameClick(e,row)}>{row?.name}</span>
            },
            width:"50"
        },
        {
            name: 'Source',
            cell: row => {
                return <span style={{color:getTextColor(row?.stats?.status)}}>{row?.stats?.status||"disabled"}</span>
            }
        },
        {
            name: 'Restart',
            cell: row =>{
                const isSelected:boolean=setSelectedData?.length>0 && rowsSelected?.find((r:any)=>r?.name===row?.name);
                return <img src={refresh} height={"20px"} width={"20px"} className={isSelected?"clickable":"non-clickable"} onClick={()=>isSelected&&restartStream(row)}/>
            }
        },
        {
            name: 'Status',
            cell: row =>{
                const isSelected:boolean=rowsSelected?.length>0 && rowsSelected?.find((r:any)=>r?.name===row?.name);
               return <label className="switch-toggle">
               <input type="checkbox" checked={row?.disabled} disabled={!isSelected} onChange={()=>disableStreamRow(row)}/>
               <span className="slider round"></span>
             </label>
            },
        },
        {
            name: 'Uptime',
            cell: row => row?.stats?.lifetime|| "-",
        },
        {
            name: 'Play/Info',
            cell: row => {
                const isSelected:boolean=rowsSelected?.length>0 && rowsSelected?.find((r:any)=>r?.name===row?.name);
                 return <img src={play} height={"20px"} width={"20px"} className={isSelected?"clickable":"non-clickable"} onClick={()=>isSelected?playStreamRow(row):null}/>
        },
        },
        {
            name: 'Delete',
            cell: row => {
                const isSelected:boolean=rowsSelected?.length>0 && rowsSelected?.find((r:any)=>r?.name===row?.name);
                return <img src={deleteIcon} height={"20px"} width={"20px"} className={isSelected?"clickable":"non-clickable"} onClick={()=>isSelected?deleteStreamRow(row):null}/>
            },
        }
    ];

    const deleteStreamRow=(deleteRow:any)=>{
      setRowDelete(deleteRow);
    }
    
    const playStreamRow=(deleteRow:any)=>{
        setShowPlayModal(true);
        setPlayStream(deleteRow);
      }

    useEffect(()=>{
      rowDelete?.name !== undefined &&setShowDeleteAlert(!showDeleteAlert);
    },[rowDelete])

    useEffect(()=>{
        dispatch(setSelectedData(selectedRows))
    },[selectedRows])

    const onRowSelected=(data:any)=>{
        setSelectedRows(data);
    }

    const handleOk = () => {
        deleteStream(rowDelete,userData).then((res:any)=>{
            setRowDelete({});
            if(res?.data?.status === 200){
                dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
             } else {
                 dispatch(setAlertProps({message:res?.data?.message,severity:res?.data?.severity}));
             }
        });
    };

    const handleClose = () => {
        setRowDelete({});
    };

    const onPlayModalClose = () => {
        setShowPlayModal(false);
    };

    const playStreamUrl=()=>{
        console.log("jjjjjjjjjjjjjjjjjjjjj");
    }
      
    const rowSelectCriteria:any=(row:any)=>rowsSelected?.find((selectRow:any)=>selectRow?.name===row.name);
    return (<div id="streams-table">
             <DataTable 
              columns={columns} 
              data={livestreams}
              selectableRows 
              pagination
              fixedHeader
              paginationPerPage={100}
              paginationRowsPerPageOptions={[50,100,150,200,250]}
              onChangeRowsPerPage={(data)=>setPageLimit(data)}
              fixedHeaderScrollHeight='70vh' 
              onSelectedRowsChange={(data:any)=> onRowSelected(data?.selectedRows)}
              selectableRowSelected={rowSelectCriteria}
              /> 
              {rowDelete?.name !== undefined && <DeleteDialog handleClose={handleClose} handleOk={handleOk} open={showDeleteAlert} selectedRow={rowDelete} />}
              {playStream?.name !== undefined && <PlayInfoModal handleClose={onPlayModalClose} handleOk={playStreamUrl} open={showPlayModal} selectedRow={playStream}/>}
              </div>);
}

export default LiveStreams;